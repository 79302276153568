import { getRedirectResult, signOut } from "firebase/auth";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { API_CLIENT, API_CONFIG } from "../../../api/config";
import { User, userFromFirestore } from "../../../domain/User";
import { auth } from "../../../firebase";
import { devFastCache } from "../../../utils/devFastCache";
import { AppLoginWaiting } from "../../AppLoginWaiting";

import { LoginView } from "./LoginView";
import { ResendEmailVerification } from "./ResendEmailVerification";

declare global {
  interface Window {
    signOut: () => unknown;
  }
}

type AppAuthState = {
  isLoggedIn: boolean;
  user: null | User;
};

const appAuthCtx = createContext<AppAuthState>({
  isLoggedIn: false,
  user: null,
});

export const useAppAuth = () => {
  return useContext(appAuthCtx);
};

/**
 * @description 認証情報のprovider
 * ログイン画面を描画。ログイン後は何も表示しない
 * firebase Authentificationを経由し、Auth0でログインをするカスタム認証を利用
 * Auth0経由でログインをすると、Firebase Authenticationにサーバがユーザ登録する
 */
export const AppAuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // 認証初期化
  const [appAuthInitialized, setAppAuthInitialized] = useState(false);
  // ログイン情報
  const [appUser, setAppUser] = useState<AppAuthState>({
    isLoggedIn: false,
    user: null,
  });
  // ログアウト関数
  // @ts-ignore
  window.signOut = async () => {
    // ログアウト前にログイン情報を初期化
    setAppUser({
      isLoggedIn: false,
      user: null,
    });
    // firebaseのサインアウト関数を実行
    await signOut(auth);
    // Auth0のログアウトURLに遷移
    location.href = API_CONFIG.URL_LOGOUT;
  };

  useEffect(() => {
    (async () => {
      await getRedirectResult(auth);
      const authUser = auth.currentUser;

      if (authUser) {
        let getUserTokenPromise = authUser.getIdToken(true).then((token) => {
          getUserToken = () => authUser.getIdToken();
          return token;
        });
        let getUserToken = async () => {
          return getUserTokenPromise;
        };
        let requestHookInjected = false;
        const initAuth = async (authRes: any) => {
          try {
            // firebaseから取得したユーザデータを状態にセット
            setAppUser({
              isLoggedIn: true,
              user: userFromFirestore({
                ...authRes.data,
                id: authRes.data.uid,
                is_disabled: false,
              }),
            });

            // トークン＆APIキー取得をAPI通信時に共通で埋め込む
            if (!requestHookInjected) {
              API_CLIENT.interceptors.request.use(
                async (config) => {
                  const idToken = await getUserToken();
                  config.headers["X-API-KEY"] = API_CONFIG.API_KEY;
                  config.headers.Authorization = `Bearer ${idToken}`;
                  return config;
                },
                (error) => {
                  alert(error);
                  return Promise.reject(error);
                }
              );
              requestHookInjected = true;
            }
          } catch (e) {
            alert(e);
          } finally {
            setAppAuthInitialized(true);
          }
        };
        const authCache = devFastCache.get("authResData");
        if (authCache !== null) {
          getUserTokenPromise = Promise.resolve(authCache.idToken);
          initAuth({ data: authCache.data });
        }
        // 認証結果を元に、クライアントはIDトークンを取得し、APIサーバにアクセスする
        const idToken = await getUserToken();
        const authRes = await API_CLIENT.post("/signin", undefined, {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": API_CONFIG.API_KEY,
            Authorization: `Bearer ${idToken}`,
          },
        });
        devFastCache.store("authResData", { idToken, data: authRes.data });
        initAuth(authRes);
      }
      setAppAuthInitialized(true);
    })();
  }, []);
  if (!appAuthInitialized)
    // 初期化できていなければ待機画面を表示
    return <AppLoginWaiting />;
  // ログインできていれば画面を描画する
  if (appUser.isLoggedIn) {
    return (
      <appAuthCtx.Provider value={appUser}>{children}</appAuthCtx.Provider>
    );
  }

  // URLを取得し、条件を満たしていればメール検証のページに遷移する
  const urlParams = new URLSearchParams(window.location.search);
  const auth0Uid = urlParams.get("u");
  const state = urlParams.get("state");
  const hash = window.location.hash;
  const isEmailVerificationSettings = hash === "#/email_verification_settings";

  // 最後にアクセスしたページの履歴をチェックする
  // auth0にリダイレクトした時にログイン画面に遷移
  return (
    <>
      {auth0Uid && state && isEmailVerificationSettings ? (
        <ResendEmailVerification idp_uid={auth0Uid} />
      ) : (
        <LoginView />
      )}
    </>
  );
};
