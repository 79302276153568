import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { ViewTypeDependSheetField } from "@/domain/ViewTypeDependSheetField";
import { Coal, Contract } from "../../domain/coal";
import { OptParams } from "../../domain/optParams";
import {
  CoalCenter,
  FiringRatioRecord,
  LoadingPort,
  Plant,
  PlantCapacity,
  PlantRestrict,
  PlantSchedulesDayRecord,
  Stock,
} from "../../domain/port";
import { Ship } from "../../domain/ship";
import { Dock, SelfTrip } from "../../domain/task";
import { TripRaw } from "./schema";
import { AllSheets, HKRShipRowKeys, HKRShipSheetKeys } from "./sheet";

type DependSheetFields<SK extends HKRShipSheetKeys> = {
  [K in keyof AllSheets[SK]]: Omit<ViewTypeDependSheetField<SK>, "key">;
};

/**
 * {...{key: {displayName, type}}
 * から
 * [...{key, displayName, type}]
 * に変換する
 */
const objToList = <SK extends HKRShipSheetKeys>(
  obj: DependSheetFields<SK>
): ViewTypeDependSheetField<keyof AllSheets[SK] & string>[] => {
  return Object.entries<Omit<ViewTypeDependSheetField<SK>, "key">>(obj).map(
    ([key, field]) => ({ key, ...field })
  ) as ViewTypeDependSheetField<keyof AllSheets[SK] & string>[];
};

const shipFieldsObj: DependSheetFields<"ships"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  amount: { displayName: "数量", type: "integer" },
  contractType: { displayName: "契約形態", type: "enum" },
  contractStart: { displayName: "契約開始", type: "date" },
  contractEnd: { displayName: "契約終了", type: "date" },
  useCountMin: { displayName: "最小使用回数", type: "integer" },
  useCountMax: { displayName: "最大使用回数", type: "integer" },
  selfTripMax: { displayName: "最大自主航海回数", type: "integer" },
  demurrageDollar: { displayName: "滞船料（ドル）", type: "integer" },
  demurrageYen: { displayName: "滞船料（円）", type: "integer" },
};

export const shipFields: ViewTypeDependSheetField<keyof Ship>[] =
  objToList(shipFieldsObj);

const coalFieldsObj: DependSheetFields<"coals"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  loadingPortId: { displayName: "積地ID", type: "string" },
};

export const coalFields: ViewTypeDependSheetField<keyof Coal>[] =
  objToList(coalFieldsObj);

const contractFieldsObj: DependSheetFields<"contracts"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  coalId: { displayName: "石炭ID", type: "string" },
  coalCenterId: { displayName: "中継基地ID", type: "string" },
  tripIdIn: { displayName: "搬入した航海ID", type: "string" },
  contractStart: { displayName: "契約開始", type: "date" },
  contractEnd: { displayName: "契約終了", type: "date" },
  shipNumAll: {
    displayName: "期間内ならいつでも配船可能な隻数",
    type: "integer",
  },
  shipNumFirstHalf: {
    displayName: "上期に配船可能な隻数",
    type: "integer",
  },
  shipNumSecondHalf: {
    displayName: "下期に配船可能な隻数",
    type: "integer",
  },
  shipNumFirstQuarter: {
    displayName: "1Qに配船可能な隻数",
    type: "integer",
  },
  shipNumSecondQuarter: {
    displayName: "2Qに配船可能な隻数",
    type: "integer",
  },
  shipNumThirdQuarter: {
    displayName: "3Qに配船可能な隻数",
    type: "integer",
  },
  shipNumFourthQuarter: {
    displayName: "4Qに配船可能な隻数",
    type: "integer",
  },
  priceType: { displayName: "価格種別", type: "enum" },
  fixedPrice: { displayName: "固定価格", type: "float" },
  coefficientA: { displayName: "係数a", type: "float" },
  coefficientB: { displayName: "係数b", type: "float" },
  referencedIndex: { displayName: "参照するglobal coal index", type: "enum" },
};

export const contractFields: ViewTypeDependSheetField<keyof Contract>[] =
  objToList(contractFieldsObj);

const loadingPortFieldsObj: DependSheetFields<"loadingPorts"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  area: { displayName: "地域", type: "enum" },
  loadingSpeed: { displayName: "積み速度", type: "integer" },
  demurrageDays: { displayName: "滞船日数", type: "integer" },
  tripDaysGo: { displayName: "往路航海日数", type: "integer" },
  tripDaysBack: { displayName: "復路航海日数", type: "integer" },
};

export const loadingPortFields: ViewTypeDependSheetField<keyof LoadingPort>[] =
  objToList(loadingPortFieldsObj);

const coalCenterFieldsObj: DependSheetFields<"coalCenters"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  area: { displayName: "地域", type: "enum" },
  loadingSpeed: { displayName: "積み速度", type: "integer" },
  unloadingSpeed: { displayName: "揚げ速度", type: "integer" },
  demurrageDays: { displayName: "滞船日数", type: "integer" },
  tripDaysGo: { displayName: "往路航海日数", type: "integer" },
  tripDaysBack: { displayName: "復路航海日数", type: "integer" },
};

export const coalCenterFields: ViewTypeDependSheetField<keyof CoalCenter>[] =
  objToList(coalCenterFieldsObj);

const plantFieldsObj: DependSheetFields<"plants"> = {
  id: { displayName: "ID", type: "string" },
  name: { displayName: "名前", type: "string" },
  consumptionHigh: { displayName: "高需要期消費量", type: "integer" },
  consumptionLow: { displayName: "低需要期消費量", type: "integer" },
  stockLowerHigh: { displayName: "高需要期在庫下限", type: "integer" },
  stockLowerLow: { displayName: "低需要期在庫下限", type: "integer" },
  unloadingSpeed: { displayName: "揚げ速度", type: "integer" },
  yardNumForAus: { displayName: "豪州炭区画数", type: "integer" },
  yardNumForNonAus: { displayName: "非豪州炭区画数", type: "integer" },
  stockAddBaselineAus: { displayName: "豪州炭配船貯炭基準量", type: "integer" },
  stockAddBaselineNonAus: {
    displayName: "非豪州炭配船貯炭基準量",
    type: "integer",
  },
};

export const plantFields: ViewTypeDependSheetField<keyof Plant>[] =
  objToList(plantFieldsObj);

const tripFieldsObj: DependSheetFields<"trips"> = {
  id: { displayName: "ID", type: "string" },
  contractId: { displayName: "契約ID", type: "string" },
  taskType: { displayName: "種別", type: "enum" },
  shipId: { displayName: "船ID", type: "string" },
  isShipFixed: { displayName: "船固定", type: "boolean" },
  loadingAmount: { displayName: "積み数量", type: "integer" },
  taskStart: { displayName: "開始日", type: "date" },
  laycanStart: { displayName: "レイキャン開始日", type: "date" },
  laycanEnd: { displayName: "レイキャン終了日", type: "date" },
  supplierArrival: { displayName: "積地到着日", type: "date" },
  loadingStart: { displayName: "積み開始日", type: "date" },
  supplierLeave: { displayName: "積地出帆日", type: "date" },
  isFixed: { displayName: "固定", type: "boolean" },
  isPlantFixed: { displayName: "揚地固定", type: "boolean" },
  plantId: { displayName: "揚地ID", type: "string" },
  unloadingAmount: { displayName: "揚げ数量", type: "integer" },
  unloadingPortArrival: { displayName: "揚地到着日", type: "date" },
  unloadingStart: { displayName: "揚げ開始日", type: "date" },
  end: { displayName: "揚地出帆日", type: "date" },
  comment: { displayName: "コメント", type: "string" },
};

export const tripFields: ViewTypeDependSheetField<keyof TripRaw>[] =
  objToList(tripFieldsObj);

const dockFieldsObj: DependSheetFields<"docks"> = {
  id: { displayName: "ID", type: "string" },
  shipId: { displayName: "船ID", type: "string" },
  startDayMin: { displayName: "ドック開始可能初日", type: "date" },
  startDayMax: { displayName: "ドック開始可能最終日", type: "date" },
  length: { displayName: "ドックの長さ", type: "integer" },
  start: { displayName: "ドック開始日", type: "date" },
  end: { displayName: "ドック港出帆日", type: "date" },
};

export const dockFields: ViewTypeDependSheetField<keyof Dock>[] =
  objToList(dockFieldsObj);

const selfTripFieldsObj: DependSheetFields<"selfTrips"> = {
  id: { displayName: "ID", type: "string" },
  shipId: { displayName: "船ID", type: "string" },
  startDayMin: { displayName: "自主航海開始可能初日", type: "date" },
  startDayMax: { displayName: "自主航海開始可能最終日", type: "date" },
  length: { displayName: "自主航海の長さ", type: "integer" },
  start: { displayName: "自主航海開始日", type: "date" },
  end: { displayName: "最終港出帆日", type: "date" },
};

export const selfTripFields: ViewTypeDependSheetField<keyof SelfTrip>[] =
  objToList(selfTripFieldsObj);

const plantRestrictFieldObj: DependSheetFields<"plantRestricts"> = {
  id: { displayName: "ID", type: "string" },
  plantId: { displayName: "発電所ID", type: "string" },
  kind: { displayName: "種別", type: "enum" },
  start: { displayName: "開始日", type: "date" },
  end: { displayName: "終了日", type: "date" },
  description: { displayName: "詳細", type: "string" },
};

export const plantRestrictFields: ViewTypeDependSheetField<
  keyof PlantRestrict
>[] = objToList(plantRestrictFieldObj);

const plantCapacityFieldObj: DependSheetFields<"plantCapacities"> = {
  plantId: { displayName: "発電所ID", type: "string" },
  start: { displayName: "開始日", type: "date" },
  end: { displayName: "終了日", type: "date" },
  yardNumForAus: { displayName: "豪州炭区画数", type: "integer" },
  yardNumForNonAus: { displayName: "非豪州炭区画数", type: "integer" },
  stockAddBaselineAus: { displayName: "豪州炭配船貯炭基準量", type: "integer" },
  stockAddBaselineNonAus: {
    displayName: "非豪州炭配船貯炭基準量",
    type: "integer",
  },
};

export const plantCapacityFields: ViewTypeDependSheetField<
  keyof PlantCapacity
>[] = objToList(plantCapacityFieldObj);

const firingRatioFieldObj: DependSheetFields<"firingRatios"> = {
  coalIdNonAus: { displayName: "非豪州炭ID", type: "string" },
  coalIdAus: { displayName: "豪州炭ID", type: "string" },
  plantId: { displayName: "発電所ID", type: "string" },
  ausRatio: { displayName: "豪州炭混焼比率", type: "float" },
};

export const firingRatioFields: ViewTypeDependSheetField<
  keyof FiringRatioRecord
>[] = objToList(firingRatioFieldObj);

const plantSchedulesDayRecordFieldObj: DependSheetFields<"plantSchedulesDayRecord"> =
  {
    date: { displayName: "日付", type: "date" },
    plantId: { displayName: "発電所ID", type: "string" },
    consumption: { displayName: "消費量", type: "float" },
    unloadingSpeed: { displayName: "揚げ速度", type: "float" },
    stockLower: { displayName: "在庫下限", type: "float" },
  };

export const plantSchedulesDayRecordFields: ViewTypeDependSheetField<
  keyof PlantSchedulesDayRecord
>[] = objToList(plantSchedulesDayRecordFieldObj);

const stockFieldObj: DependSheetFields<"stocks"> = {
  date: { displayName: "日付", type: "date" },
  plantId: { displayName: "発電所ID", type: "string" },
  coalId: { displayName: "石炭ID", type: "string" },
  amount: { displayName: "在庫量", type: "integer" },
};

export const stockFields: ViewTypeDependSheetField<keyof Stock>[] =
  objToList(stockFieldObj);

const paramFieldObj: DependSheetFields<"params"> = {
  startDay: { displayName: "最適化開始日", type: "date" },
  endDay: { displayName: "最適化終了日", type: "date" },
  baseDay: { displayName: "基準日", type: "date" },
  exchange: { displayName: "為替", type: "integer" },
  selfTripLength: { displayName: "自主航海の長さ", type: "integer" },
  stockOverPenaltyWeight: {
    displayName: "貯炭超過ペナルティ重み",
    type: "float",
  },
};

export const paramFields: ViewTypeDependSheetField<keyof OptParams>[] =
  objToList(paramFieldObj);

export const allDependSheets: ViewTypeDependSheet<
  HKRShipSheetKeys,
  HKRShipRowKeys
>[] = [
  {
    key: "ships",
    displayName: "船",
    dependSheetFields: shipFields,
  },
  {
    key: "coals",
    displayName: "石炭銘柄",
    dependSheetFields: coalFields,
  },
  {
    key: "contracts",
    displayName: "石炭契約",
    dependSheetFields: contractFields,
  },
  {
    key: "loadingPorts",
    displayName: "積地",
    dependSheetFields: loadingPortFields,
  },
  {
    key: "plants",
    displayName: "発電所",
    dependSheetFields: plantFields,
  },
  {
    key: "plantRestricts",
    displayName: "発電所制約",
    dependSheetFields: plantRestrictFields,
  },
  {
    key: "plantCapacities",
    displayName: "発電所在庫容量",
    dependSheetFields: plantCapacityFields,
  },
  {
    key: "coalCenters",
    displayName: "石炭中継基地",
    dependSheetFields: coalCenterFields,
  },
  {
    key: "firingRatios",
    displayName: "混焼比率",
    dependSheetFields: firingRatioFields,
  },
  {
    key: "plantSchedulesDayRecord",
    displayName: "発電所スケジュール",
    dependSheetFields: plantSchedulesDayRecordFields,
  },
  { key: "trips", displayName: "航海", dependSheetFields: tripFields },
  { key: "docks", displayName: "ドック", dependSheetFields: dockFields },
  {
    key: "selfTrips",
    displayName: "自主航海",
    dependSheetFields: selfTripFields,
  },
  { key: "stocks", displayName: "在庫量", dependSheetFields: stockFields },
  {
    key: "params",
    displayName: "最適化パラメータ",
    dependSheetFields: paramFields,
  },
];
