import loadable from "@loadable/component";

import { viewTypeStockDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeStorageStockView: StrictViewType = {
  key: "planiumChemicalStorageStockView",
  displayName: "保管所在庫推移ビュー",
  render: ViewRender as any,
  dependSheets: viewTypeStockDependSheets,
  configSchemas: [
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ(','区切りで複数入力可)",
      type: "string",
      typeFields: null,
      typeParameters: null,
    },
  ],
};
