export type PermissionsRaw = string[];

export type RoleRaw = {
  id: string;
  name: string;
  description?: string;
  permissions: PermissionsRaw;
  created_at: string;
  updated_at: string;
  is_disabled: boolean;
};

export type Role = Omit<RoleRaw, "permissions"> & {
  permissions: RolePermissions;
};

export type RoleRawForm = {
  name: string;
  description?: string;
  permissions: PermissionsRaw;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Permissions
// 型名が重複するので、RolePermissionsとして定義
export type RolePermissions = {
  [K in
    | PermissionsKey
    | PermissionsWithIdKey
    | PermissionsWithIdsKey]: K extends PermissionsWithIdKey
    ? AllIdAction
    : K extends PermissionsWithIdsKey
      ? AllIdsAction
      : AllAction;
};

export const permissionsActions = [
  "read",
  "write",
  "delete",
] as const satisfies string[];
export type AllActionsType = (typeof permissionsActions)[number];
export type AllAction = {
  [K in AllActionsType]?: boolean;
};

export type AllIdAction = {
  [K in AllActionsType]: string[];
};

export type AllIdsAction = {
  [K in AllActionsType]: {
    versionId: string;
    sheetId: string;
  }[];
};

export const permissionsKeys = [
  "organizations",
  "members",
  "roles",
  "version_categories",
  "versions",
  "optimize",
  "evaluate",
  "algorithm_versions",
  "view_masters",
  "connect_masters",
  "connects",
  "connect_jobs",
  "connect_job_histories",
] as const satisfies string[];

export const permissionsWithIdKeys = [
  "version_category",
  "versions_version_category",
  "sheet",
  "version",
] as const satisfies string[];

export const permissionsWithIdsKeys = [
  "version_sheet",
] as const satisfies string[];

export type PermissionsKey = (typeof permissionsKeys)[number];
export type PermissionsWithIdKey = (typeof permissionsWithIdKeys)[number];
export type PermissionsWithIdsKey = (typeof permissionsWithIdsKeys)[number];
export type PermissionsAction = (typeof permissionsActions)[number];

export type AllPermissionsKey =
  | PermissionsKey
  | PermissionsWithIdKey
  | PermissionsWithIdsKey;

export const emptyRolePermissions = {
  organizations: {
    read: false,
    write: false,
  },
  members: {
    read: false,
    write: false,
    delete: false,
  },
  roles: {
    read: false,
    write: false,
    delete: false,
  },
  version_categories: {
    read: false,
    write: false,
    delete: false,
  },
  algorithm_versions: {
    read: false,
  },
  view_masters: {
    read: false,
  },
  connect_masters: {
    read: false,
  },
  connects: {
    read: false,
    write: false,
    delete: false,
  },
  connect_jobs: {
    write: false,
  },
  connect_job_histories: {
    read: false,
  },
  optimize: {
    write: false,
  },
  evaluate: {
    write: false,
  },
  versions: {
    read: false,
    write: false,
    delete: false,
  },
  version_category: { read: [], write: [], delete: [] },
  versions_version_category: { read: [], write: [], delete: [] },
  sheet: { read: [], write: [], delete: [] },
  version: { read: [], write: [], delete: [] },
  version_sheet: { read: [], write: [], delete: [] },
} as const satisfies RolePermissions;

export const orgAdminRolePermissions = {
  organizations: {
    read: true,
    write: true,
  },
  members: {
    read: true,
    write: true,
    delete: true,
  },
  roles: {
    read: true,
    write: true,
    delete: true,
  },
  version_categories: {
    read: true,
    write: true,
    delete: true,
  },
  algorithm_versions: {
    read: true,
  },
  view_masters: {
    read: true,
  },
  connect_masters: {
    read: true,
  },
  connects: {
    read: true,
    write: true,
    delete: true,
  },
  connect_jobs: {
    write: true,
  },
  connect_job_histories: {
    read: true,
  },
  optimize: {
    write: true,
  },
  evaluate: {
    write: true,
  },
  versions: {
    read: true,
    write: true,
    delete: true,
  },
  version_category: { read: [], write: [], delete: [] },
  versions_version_category: {
    read: [],
    write: [],
    delete: [],
  },
  sheet: { read: [], write: [], delete: [] },
  version: { read: [], write: [], delete: [] },
  version_sheet: { read: [], write: [], delete: [] },
} as const satisfies RolePermissions;

export const orgEditorRolePermissions = {
  organizations: {
    read: true,
    write: false,
  },
  members: {
    read: true,
    write: false,
    delete: false,
  },
  roles: {
    read: true,
    write: false,
    delete: false,
  },
  version_categories: {
    read: true,
    write: false,
    delete: false,
  },
  algorithm_versions: {
    read: true,
  },
  view_masters: {
    read: true,
  },
  connect_masters: {
    read: true,
  },
  connects: {
    read: true,
    write: false,
    delete: false,
  },
  connect_jobs: {
    write: true,
  },
  connect_job_histories: {
    read: true,
  },
  optimize: {
    write: true,
  },
  evaluate: {
    write: true,
  },
  versions: {
    read: true,
    write: true,
    delete: true,
  },
  version_category: { read: [], write: [], delete: [] },
  versions_version_category: {
    read: [],
    write: [],
    delete: [],
  },
  sheet: { read: [], write: [], delete: [] },
  version: { read: [], write: [], delete: [] },
  version_sheet: { read: [], write: [], delete: [] },
} as const satisfies RolePermissions;

export const orgViewerRolePermissions = {
  organizations: {
    read: true,
    write: false,
  },
  members: {
    read: true,
    write: false,
    delete: false,
  },
  roles: {
    read: true,
    write: false,
    delete: false,
  },
  version_categories: {
    read: true,
    write: false,
    delete: false,
  },
  algorithm_versions: {
    read: true,
  },
  view_masters: {
    read: true,
  },
  connect_masters: {
    read: true,
  },
  connects: {
    read: true,
    write: false,
    delete: false,
  },
  connect_jobs: {
    write: false,
  },
  connect_job_histories: {
    read: true,
  },
  optimize: {
    write: false,
  },
  evaluate: {
    write: false,
  },
  versions: {
    read: true,
    write: false,
    delete: false,
  },
  version_category: { read: [], write: [], delete: [] },
  versions_version_category: {
    read: [],
    write: [],
    delete: [],
  },
  sheet: { read: [], write: [], delete: [] },
  version: { read: [], write: [], delete: [] },
  version_sheet: { read: [], write: [], delete: [] },
} as const satisfies RolePermissions;
