import { format, formatISO, parse, parseISO } from "date-fns";
import { ja } from "date-fns/locale/ja";
import React, { useState } from "react";

import { Version, useSetVersion } from "../../../../../domain/Version";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { DATETIME_WITH_SECONDS_FORMAT } from "@/config/date";

export const DialogVersionEnable: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setVersion = useSetVersion();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const selectedVersion = versions.find(
    ({ id }) => id === selectedVersionIds[0]
  );

  const runEnable = async () => {
    if (selectedVersion) {
      setDialogPhase("in_progress");
      const lastModifiedAt = format(new Date(), DATETIME_WITH_SECONDS_FORMAT, {
        locale: ja,
      });
      try {
        await setVersion(
          {
            ...selectedVersion,
            // 古いバージョンはoptimizeJobWorkingDirを持っていない場合がある
            // その場合、optimizeJobDirが存在すればそこからコピーする
            optimizeJobWorkingDir:
              selectedVersion.optimizeJobWorkingDir ??
              selectedVersion.optimizeJobDir ??
              null,
            lastModifiedAt,
            isDisabled: false,
          },
          {}
        );
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "enable",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runEnable,
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};
