import { TaskStrictViewType } from "@/viewType/viewTypes/Optium/cptDom/strictViewType";
import loadable from "@loadable/component";

const ViewRender = loadable(() => import("./render"));

export const viewTypeBerthScheduleView: TaskStrictViewType = {
	key: "cptdomBerthScheduleView",
	displayName: "バースビュー",
	render: ViewRender as any,
	dependSheets: [
		{
			key: "config",
			displayName: "設定",
			dependSheetFields: [
				{
					key: "main_oil_group",
					displayName: "main_oil_group",
					type: "enum",
				},
			],
		},
		{
			key: "shipMaster",
			displayName: "船マスタ",
			dependSheetFields: [
				{
					key: "ship_code",
					displayName: "ship_code",
					type: "string",
				},
				{
					key: "ship_name",
					displayName: "ship_name",
					type: "string",
				},
				{
					key: "oil_group",
					displayName: "oil_group",
					type: "enum",
				},
			],
		},
		{
			key: "portMaster",
			displayName: "基地マスタ",
			dependSheetFields: [
				{
					key: "port_code",
					displayName: "port_code",
					type: "string",
				},
				{
					key: "port_name",
					displayName: "port_name",
					type: "string",
				},
				{
					key: "port_type",
					displayName: "port_type",
					type: "enum",
				},
				{
					key: "contract_type",
					displayName: "contract_type",
					type: "enum",
				},
			],
		},
		{
			key: "oilMaster",
			displayName: "油種マスタ",
			dependSheetFields: [
				{
					key: "oil_code",
					displayName: "oil_code",
					type: "string",
				},
				{
					key: "oil_name",
					displayName: "oil_name",
					type: "string",
				},
				{
					key: "oil_group",
					displayName: "oil_group",
					type: "enum",
				},
			],
		},
		{
			key: "berthMaster",
			displayName: "バースマスタ",
			dependSheetFields: [
				{
					key: "berth_code",
					displayName: "berth_code",
					type: "string",
				},
				{
					key: "berth_name",
					displayName: "berth_name",
					type: "string",
				},
				{
					key: "port_code",
					displayName: "port_code",
					type: "string",
				},
				{
					key: "white_ship_size_min",
					displayName: "white_ship_size_min",
					type: "integer",
				},
				{
					key: "white_ship_size_max",
					displayName: "white_ship_size_max",
					type: "integer",
				},
				{
					key: "black_ship_size_min",
					displayName: "black_ship_size_min",
					type: "integer",
				},
				{
					key: "black_ship_size_max",
					displayName: "black_ship_size_max",
					type: "integer",
				},
			],
		},
		{
			key: "voyagePlan",
			displayName: "転送計画",
			dependSheetFields: [
				{
					key: "task_id",
					displayName: "task_id",
					type: "string",
				},
				{
					key: "ship_code",
					displayName: "ship_code",
					type: "string",
				},
				{
					key: "is_fixed",
					displayName: "is_fixed",
					type: "boolean",
				},
				{
					key: "request_id",
					displayName: "request_id",
					type: "string",
				},
				{
					key: "fixed_mode",
					displayName: "fixed_mode",
					type: "enum",
				},
				{
					key: "time_calculation_mode",
					displayName: "time_calculation_mode",
					type: "enum",
				},
				{
					key: "ship_fixed_mode",
					displayName: "ship_fixed_mode",
					type: "enum",
				},
				{
					key: "is_berth_fixed",
					displayName: "is_berth_fixed",
					type: "boolean",
				},
				{
					key: "comment",
					displayName: "comment",
					type: "string",
				},
			],
		},
		{
			key: "coordinateMaster",
			displayName: "座標マスタ",
			dependSheetFields: [
				{
					key: "place",
					displayName: "place",
					type: "string",
				},
				{
					key: "lat",
					displayName: "lat",
					type: "float",
				},
				{
					key: "lon",
					displayName: "lon",
					type: "float",
				},
			],
		},
		{
			key: "cargoHandling",
			displayName: "荷役",
			dependSheetFields: [
				{
					key: "cargo_handling_id",
					displayName: "cargo_handling_id",
					type: "string",
				},
				{
					key: "task_id",
					displayName: "task_id",
					type: "string",
				},
				{
					key: "port_code",
					displayName: "port_code",
					type: "string",
				},
				{
					key: "berth_code",
					displayName: "berth_code",
					type: "string",
				},
				{
					key: "date",
					displayName: "date",
					type: "date",
				},
				{
					key: "timeframe",
					displayName: "timeframe",
					type: "enum",
				},
				{
					key: "handling_type",
					displayName: "handling_type",
					type: "enum",
				},
				{
					key: "cargo_handling_start_time",
					displayName: "cargo_handling_start_time",
					type: "date-time",
				},
				{
					key: "cargo_handling_end_time",
					displayName: "cargo_handling_end_time",
					type: "date-time",
				},
				{
					key: "earliest_visit_date",
					displayName: "earliest_visit_date",
					type: "date",
				},
				{
					key: "latest_visit_date",
					displayName: "latest_visit_date",
					type: "date",
				},
				{
					key: "is_port_fixed",
					displayName: "is_port_fixed",
					type: "boolean",
				},
			],
		},
		{
			key: "cargo",
			displayName: "貨物",
			dependSheetFields: [
				{
					key: "oil_code",
					displayName: "oli_code",
					type: "string",
				},
				{
					key: "oil_amount",
					displayName: "oil_amount",
					type: "float",
				},
				{
					key: "load_id",
					displayName: "load_id",
					type: "string",
				},
				{
					key: "unload_id",
					displayName: "unload_id",
					type: "string",
				},
			],
		},

		{
			key: "shipUnavailable",
			displayName: "利用不可",
			dependSheetFields: [
				{
					key: "task_id",
					displayName: "task_id",
					type: "string",
				},
				{
					key: "ship_code",
					displayName: "ship_code",
					type: "string",
				},
				{
					key: "start_place",
					displayName: "start_place",
					type: "string",
				},
				{
					key: "end_place",
					displayName: "end_place",
					type: "string",
				},
				{
					key: "start_date",
					displayName: "start_date",
					type: "date",
				},
				{
					key: "start_timeframe",
					displayName: "start_timeframe",
					type: "enum",
				},
				{
					key: "end_date",
					displayName: "end_date",
					type: "date",
				},
				{
					key: "end_timeframe",
					displayName: "end_timeframe",
					type: "enum",
				},
				{
					key: "is_clear_previous_cargo",
					displayName: "is_clear_previous_cargo",
					type: "boolean",
				},
				{
					key: "event_name",
					displayName: "event_name",
					type: "enum",
				},
				{
					key: "comment",
					displayName: "comment",
					type: "string",
				},
			],
		},
	],
	configSchemas: [],
};
