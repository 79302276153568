import loadable from "@loadable/component";

import {
  paramFields,
  plantFields,
  plantRestrictFields,
  plantSchedulesDayRecordFields,
} from "../../ssui/types/fields";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeHkrShipPlantSchedules: StrictViewType = {
  key: "hkrShipPlantSchedules",
  displayName: "HKR-SHIP 発電所スケジュール",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "plantSchedulesDayRecord",
      displayName: "発電所スケジュール",
      dependSheetFields: plantSchedulesDayRecordFields,
    },
    {
      key: "plants",
      displayName: "発電所マスタ",
      dependSheetFields: plantFields,
    },
    {
      key: "plantRestricts",
      displayName: "発電所制約",
      dependSheetFields: plantRestrictFields,
    },
    {
      key: "params",
      displayName: "最適化パラメータ",
      dependSheetFields: paramFields,
    },
  ],
  configSchemas: [],
  isDisabledEditHistoryShortcuts: true,
};
