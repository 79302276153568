import { BatchGanttViewTypeDependSheet } from "./params";
/**
 * ViewType定義時引数
 */
const batchGanttAlertDependSheet: BatchGanttViewTypeDependSheet = {
  key: "alert",
  displayName: "アラート詳細",
  dependSheetFields: [
    {
      key: "id",
      displayName: "アラート詳細ID",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "category",
      displayName: "アラートカテゴリ",
      type: "string",
    },
    {
      key: "message",
      displayName: "詳細",
      type: "string",
    },
    {
      key: "level",
      displayName: "アラートレベル",
      type: "string",
    },
    {
      key: "viewInfo",
      displayName: "アラート情報",
      type: "string",
    },
    {
      key: "simpleMessage",
      displayName: "概要",
      type: "string",
    },
  ],
};
const batchGanttBatchTaskMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "batchTaskMaster",
  displayName: "バッチタスクマスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "deviceName",
      displayName: "使用装置",
      type: "string",
    },
    {
      key: "mainItemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "quantityPerBatch",
      displayName: "1バッチにおける生成量",
      type: "float",
    },
    {
      key: "bomVersion",
      displayName: "BOMのバージョン指定",
      type: "string",
    },
    {
      key: "preProcessMinutes",
      displayName: "前処理時間",
      type: "integer",
    },
    {
      key: "postProcessMinutes",
      displayName: "後処理時間",
      type: "integer",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
    {
      key: "isIntoStoragePerBatches",
      displayName: "バッチ毎在庫反映",
      type: "boolean",
    },
    {
      key: "leadTime",
      displayName: "リードタイム",
      type: "integer",
    },
    {
      key: "nonBusinessDayTaskAllowed",
      displayName: "非営業日タスク実行可否",
      type: "boolean",
    },
  ],
};

const batchGanttBatchTaskPlanDependSheet: BatchGanttViewTypeDependSheet = {
  key: "batchTaskPlan",
  displayName: "バッチタスク計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "処理開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskStartDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskEndDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "処理終了時刻",
      type: "date-time",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "beforeBatchTaskPlanId",
      displayName: "前タスク",
      type: "string",
    },
    {
      key: "isHighlighted",
      displayName: "ハイライト",
      type: "boolean",
    },
    {
      key: "comment",
      displayName: "コメント",
      type: "string",
    },
  ],
};

const batchGanttBatchTaskProcessTimeMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskProcessTimeMaster",
    displayName: "バッチタスク所要時間マスタ",
    dependSheetFields: [
      {
        key: "batchTaskName",
        displayName: "バッチタスク名",
        type: "string",
      },
      {
        key: "batchCount",
        displayName: "バッチ数",
        type: "integer",
      },
      {
        key: "processMinutes",
        displayName: "所要時間[min]",
        type: "integer",
      },
    ],
  };

const batchGanttBatchTaskRawMaterialPlanDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskRawMaterialPlan",
    displayName: "バッチタスク原料使用計画",
    dependSheetFields: [
      {
        key: "batchTaskId",
        displayName: "バッチタスクID",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "数量",
        type: "float",
      },
      {
        key: "itemName",
        displayName: "原料品目名",
        type: "string",
      },
      {
        key: "fromStorageName",
        displayName: "原料使用保管庫",
        type: "string",
      },
    ],
  };

const batchGanttBatchTaskStoragePlanDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskStoragePlan",
    displayName: "バッチタスク生成品保管計画",
    dependSheetFields: [
      {
        key: "batchTaskId",
        displayName: "バッチタスクID",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "生成量",
        type: "float",
      },
      {
        key: "itemName",
        displayName: "生成品品目名",
        type: "string",
      },
      {
        key: "toStorageName",
        displayName: "生成品保管庫",
        type: "string",
      },
    ],
  };

const batchGanttBomByproductItemMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "bomByproductItemMaster",
    displayName: "BOM副産物マスタ",
    dependSheetFields: [
      {
        key: "itemName",
        displayName: "品目名",
        type: "string",
      },
      {
        key: "version",
        displayName: "バージョン",
        type: "string",
      },
      {
        key: "byproductItemName",
        displayName: "副産物名",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "生成量",
        type: "float",
      },
    ],
  };

const batchGanttBomMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "bomMaster",
  displayName: "BOM",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "version",
      displayName: "バージョン",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "生成量",
      type: "float",
    },
  ],
};

const batchGanttBomRawMaterialMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "bomRawMaterialMaster",
    displayName: "BOM原材料マスタ",
    dependSheetFields: [
      {
        key: "itemName",
        displayName: "品目名",
        type: "string",
      },
      {
        key: "version",
        displayName: "バージョン",
        type: "string",
      },
      {
        key: "rawMaterialItemName",
        displayName: "原材料名",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "使用量",
        type: "float",
      },
    ],
  };

const batchGanttDeviceMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "deviceMaster",
  displayName: "装置マスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "integer",
    },
    {
      key: "deviceName",
      displayName: "装置名",
      type: "string",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
  ],
};

const batchGanttDeviceMaintenanceInputDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "deviceMaintenanceInput",
    displayName: "装置メンテナンス計画",
    dependSheetFields: [
      {
        key: "deviceName",
        displayName: "装置名",
        type: "string",
      },
      {
        key: "startDate",
        displayName: "メンテナンス開始日",
        type: "date",
      },
      {
        key: "endDate",
        displayName: "メンテナンス終了日",
        type: "date",
      },
      {
        key: "comment",
        displayName: "コメント",
        type: "string",
      },
    ],
  };

const batchGanttItemMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "itemMaster",
  displayName: "品目マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ",
      type: "string",
    },
  ],
};

const batchGanttStorageItemMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "storageItemMaster",
  displayName: "保管所品目マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "保管できる品目",
      type: "string",
    },
  ],
};

const batchGanttSwitchingBatchTaskMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "switchingBatchTaskMaster",
    displayName: "バッチタスク切り替えマスタ",
    dependSheetFields: [
      {
        key: "batchTaskNameBefore",
        displayName: "切り替え前",
        type: "string",
      },
      {
        key: "batchTaskNameAfter",
        displayName: "切り替え後",
        type: "string",
      },
      {
        key: "switchingMinutes",
        displayName: "切り替え時間",
        type: "integer",
      },
      {
        key: "switchingCost",
        displayName: "切り替え費用",
        type: "float",
      },
    ],
  };

const batchGanttTaskDeviceTypeMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "taskDeviceTypeMaster",
    displayName: "タスク装置タイプマスタ",
    dependSheetFields: [
      {
        key: "taskDeviceType",
        displayName: "タイプ",
        type: "string",
      },
      {
        key: "taskLabel",
        displayName: "タスクラベル",
        type: "string",
      },
      {
        key: "deviceLabel",
        displayName: "装置ラベル",
        type: "string",
      },
    ],
  };

export const batchGanttParamsSheet: BatchGanttViewTypeDependSheet = {
  key: "params",
  displayName: "最適化パラメータ",
  dependSheetFields: [
    {
      key: "evaluateStartDate",
      displayName: "評価を開始する日",
      type: "date",
    },
    {
      key: "evaluateEndDate",
      displayName: "評価を終了する日",
      type: "date",
    },
    {
      key: "optimizeStartDate",
      displayName: "自動ドラフトを開始する日",
      type: "date",
    },
    {
      key: "optimizeEndDate",
      displayName: "自動ドラフトを終了する日",
      type: "date",
    },
    {
      key: "switchingCostToPenaltyRate",
      displayName: "切替コストとペナルティの比率",
      type: "float",
    },
    {
      key: "offsetMinutes",
      displayName: "始業時刻 (min)",
      type: "integer",
    },
  ],
};

const batchGanttBusinessDayCalendarDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "businessDayCalendar",
    displayName: "営業日カレンダー",
    dependSheetFields: [
      {
        key: "date",
        displayName: "日付",
        type: "date",
      },
    ],
  };

const batchGanttLinkTaskMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "linkTaskMaster",
  displayName: "連続工程マスタ",
  dependSheetFields: [
    {
      key: "beforeTaskName",
      displayName: "前工程",
      type: "string",
    },
    {
      key: "beforeTaskBatchCount",
      displayName: "前工程バッチ数",
      type: "integer",
    },
    {
      key: "afterTaskName",
      displayName: "後工程",
      type: "string",
    },
    {
      key: "intervalMinutes",
      displayName: "前工程からの経過時間",
      type: "integer",
    },
  ],
};

const batchGanttScoreSummariesDependSheet: BatchGanttViewTypeDependSheet = {
  key: "scoreSummaries",
  displayName: "最適化スコアサマリ",
  dependSheetFields: [
    {
      key: "name",
      displayName: "名称",
      type: "string",
    },
    {
      key: "parent",
      displayName: "親サマリ",
      type: "string",
    },
    {
      key: "value",
      displayName: "値",
      type: "string",
    },
    {
      key: "value_type",
      displayName: "データ型",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "is_higher_rated",
      displayName: "評価の高低",
      type: "boolean",
    },
    {
      key: "sort",
      displayName: "ソート順",
      type: "integer",
    },
  ],
};

const batchGanttUtilityCostTypeMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "utilityCostTypeMaster",
    displayName: "ユーティリティ種別マスタ",
    dependSheetFields: [
      {
        key: "utilityCostType",
        displayName: "ユーティリティ名",
        type: "string",
      },
      {
        key: "costUnit",
        displayName: "単位",
        type: "string",
      },
      {
        key: "scoreCoefficient",
        displayName: "スコア係数",
        type: "float",
      },
      {
        key: "limitPerDay",
        displayName: "日限度量",
        type: "float",
      },
    ],
  };

const batchGanttBatchTaskUtilityMasterDependSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskUtilityMaster",
    displayName: "バッチタスクユーティリティマスタ",
    dependSheetFields: [
      {
        key: "batchTaskName",
        displayName: "バッチタスク名",
        type: "string",
      },
      {
        key: "utilityType",
        displayName: "ユーティリティ名",
        type: "string",
      },
      {
        key: "quantity",
        displayName: "バッチあたり使用量",
        type: "float",
      },
    ],
  };

export const viewTypeBitMaskToPeopleCountMasterSheet: BatchGanttViewTypeDependSheet =
  {
    key: "bitMaskToPeopleCountMaster",
    displayName: "ビットマスクから人数への変換マスタ",
    dependSheetFields: [
      {
        key: "bitMask",
        displayName: "ビットマスク",
        type: "string",
      },
      {
        key: "peopleCount",
        displayName: "人数",
        type: "integer",
      },
    ],
  };

export const viewTypeBatckTaskToPeopleCountMasterSheet: BatchGanttViewTypeDependSheet =
  {
    key: "batchTaskToPeopleCountMaster",
    displayName: "バッチタスクから人数への変換マスタ",
    dependSheetFields: [
      {
        key: "batchTask",
        displayName: "バッチタスク名",
        type: "string",
      },
      {
        key: "peopleCount",
        displayName: "人数",
        type: "integer",
      },
    ],
  };

const batchGanttSalesInputDependSheet: BatchGanttViewTypeDependSheet = {
  key: "salesInput",
  displayName: "販売計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shipmentDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "shippingFlag",
      displayName: "出荷フラグ",
      type: "boolean",
    },
  ],
};

const batchGanttShippingPlanDependSheet: BatchGanttViewTypeDependSheet = {
  key: "shippingPlan",
  displayName: "出荷計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shippingDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const batchGanttRawMaterialMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "rawMaterialMaster",
  displayName: "原料マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目名",
      type: "string",
    },
    {
      key: "supplierName",
      displayName: "仕入れ先名",
      type: "string",
    },
    {
      key: "leadTime",
      displayName: "リードタイム（分）",
      type: "integer",
    },
    {
      key: "lowerBoundStock",
      displayName: "発注点在庫",
      type: "float",
    },
    {
      key: "quantity",
      displayName: "発注量",
      type: "float",
    },
    {
      key: "price",
      displayName: "価格",
      type: "float",
    },
  ],
};

const batchGanttPurchasePlanDependSheet: BatchGanttViewTypeDependSheet = {
  key: "purchasePlan",
  displayName: "原料購買計画",
  dependSheetFields: [
    {
      key: "orderDate",
      displayName: "発注日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "supplierName",
      displayName: "仕入先",
      type: "string",
    },
    {
      key: "dueDate",
      displayName: "納期",
      type: "date",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "toStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const batchGanttStorageMasterDependSheet: BatchGanttViewTypeDependSheet = {
  key: "storageMaster",
  displayName: "保管所マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所名",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "lowerCapacity",
      displayName: "下限容量",
      type: "float",
    },
    {
      key: "upperCapacity",
      displayName: "上限容量",
      type: "float",
    },
    {
      key: "canStoreMultipleItems",
      displayName: "一度に複数種類保管可",
      type: "boolean",
    },
  ],
};

export const batchGanttAdjustmentInputSheet: BatchGanttViewTypeDependSheet = {
  key: "adjustmentInput",
  displayName: "在庫実績入力",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
  ],
};

export const batchGanttDependSheets = [
  batchGanttAlertDependSheet,
  batchGanttBatchTaskMasterDependSheet,
  batchGanttBatchTaskPlanDependSheet,
  batchGanttBatchTaskProcessTimeMasterDependSheet,
  batchGanttBatchTaskRawMaterialPlanDependSheet,
  batchGanttBatchTaskStoragePlanDependSheet,
  batchGanttBomByproductItemMasterDependSheet,
  batchGanttBomMasterDependSheet,
  batchGanttBomRawMaterialMasterDependSheet,
  batchGanttDeviceMasterDependSheet,
  batchGanttDeviceMaintenanceInputDependSheet,
  batchGanttItemMasterDependSheet,
  batchGanttStorageItemMasterDependSheet,
  batchGanttSwitchingBatchTaskMasterDependSheet,
  batchGanttTaskDeviceTypeMasterDependSheet,
  batchGanttParamsSheet,
  batchGanttBusinessDayCalendarDependSheet,
  batchGanttLinkTaskMasterDependSheet,
  batchGanttScoreSummariesDependSheet,
  batchGanttUtilityCostTypeMasterDependSheet,
  batchGanttBatchTaskUtilityMasterDependSheet,
  viewTypeBitMaskToPeopleCountMasterSheet,
  viewTypeBatckTaskToPeopleCountMasterSheet,
  batchGanttSalesInputDependSheet,
  batchGanttShippingPlanDependSheet,
  batchGanttRawMaterialMasterDependSheet,
  batchGanttPurchasePlanDependSheet,
  batchGanttStorageMasterDependSheet,
  batchGanttAdjustmentInputSheet,
];
