import { DefaultButton, FontIcon } from "@fluentui/react";
import classNames from "classnames";
import React, { useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";

import styles from "./index.module.css";

import { generateId } from "@/utils/generateId";

export type DragAndDropAcceptFileType =
  | "excel"
  | "csv"
  | "json"
  | "yaml"
  | "zip";

export const dragAndDropAcceptFiles: {
  [acceptFileType in DragAndDropAcceptFileType]: Accept;
} = {
  excel: {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/vnd.ms-excel": [".xls"],
  },
  csv: {
    "text/csv": [".csv"],
  },
  json: {
    "application/json": [".json"],
  },
  yaml: { "application/yaml": [".yml"] },
  zip: { "application/zip": [".zip"] },
};

export type DragAndDropUploadData = {
  file: File;
  id: string;
  isDisableDelete: boolean;
};

/**
 * @description ドラッグ&ドロップでデータをアップする部分を描画
 * アップロードされたデータのstateは外部から注入する前提
 */
export const DragAndDropFields: React.FC<{
  accept: Accept;
  isDisableCancelButton: boolean;
  uploadedFileList: DragAndDropUploadData[];
  setUploadedFileList: React.Dispatch<
    React.SetStateAction<DragAndDropUploadData[]>
  >;
  maxFileNumber: number;
  getExtElement?: (id: DragAndDropUploadData["id"]) => React.ReactNode;
  fileTypes: DragAndDropAcceptFileType[];
}> = ({
  accept,
  isDisableCancelButton,
  uploadedFileList,
  setUploadedFileList,
  maxFileNumber,
  getExtElement,
  fileTypes,
}) => {
  const disableDragAndDrop = uploadedFileList.length >= maxFileNumber;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let nowFilesNum = uploadedFileList.length;
      const newUploadedDataList: DragAndDropUploadData[] = [];
      // 限界までデータを追加
      for (const file of acceptedFiles) {
        if (nowFilesNum >= maxFileNumber) break;
        newUploadedDataList.push({
          file,
          id: generateId(),
          isDisableDelete: false,
        });
        nowFilesNum++;
      }
      // 状態更新
      setUploadedFileList((state) => [...state, ...newUploadedDataList]);
    },
    [setUploadedFileList, uploadedFileList]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: disableDragAndDrop,
  });

  return (
    <div className={styles.dragAndDrop}>
      {!disableDragAndDrop && (
        <div
          className={classNames(styles.file, {
            [styles.drag]: isDragActive,
            [styles.disable]: disableDragAndDrop,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <FontIcon
            iconName={"DocumentApproval"}
            style={{
              fontSize: 20,
              marginBottom: "5px",
            }}
          />
          {`${
            fileTypes
              .map((fileType) => {
                if (fileType == "excel") return "Excel";
                return fileType.toUpperCase();
              })
              .join(", ") ?? "不明な"
          }ファイルをここにドラッグアンドドロップ`}
          <div> {`(${maxFileNumber}件までアップロード可能)`}</div>
        </div>
      )}
      {uploadedFileList.length > 0 ? (
        <>
          <div className={styles.uploadedFileList}>{`読み込みデータ`}</div>
          {uploadedFileList.map(({ file, id, isDisableDelete }) => {
            const kBSize = file.size / 1000;
            return (
              <div className={styles.uploadedData} key={id}>
                <div className={styles.leftContent}>
                  <div className={styles.info}>
                    <FontIcon
                      iconName={"DocumentApproval"}
                      style={{
                        fontSize: 20,
                        marginRight: "5px",
                      }}
                    />
                    <div className={styles.name} title={file.name}>
                      {file.name}
                    </div>
                  </div>
                  <div>
                    {String(Math.round(kBSize * 10) / 10)}KB
                    <DefaultButton
                      text="削除"
                      disabled={isDisableCancelButton || isDisableDelete}
                      styles={{
                        root: {
                          margin: "0 10px",
                          width: "10px",
                          border: "none",
                        },
                      }}
                      style={{ color: "var(--figma-color-status-error)" }}
                      onClick={() => {
                        setUploadedFileList((state) =>
                          state.filter((v) => v.id !== id)
                        );
                      }}
                    />
                  </div>
                </div>
                {getExtElement ? getExtElement(id) : null}
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};
