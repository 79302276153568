import loadable from "@loadable/component";

import { viewTypePSIDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypePSIView: StrictViewType = {
  key: "planiumChemicalPSIView",
  displayName: "PSI表",
  render: ViewRender as any,
  dependSheets: viewTypePSIDependSheets,
  configSchemas: [
    {
      key: "cut",
      displayName: "表示タイプ (Item / Storage)",
      type: "string",
      typeFields: null,
      typeParameters: null,
    },
  ],
};
