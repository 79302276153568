import React, { useState } from "react";

import { Sheet, useSetSheet } from "../../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSetSheetDataRow,
} from "../../../../../domain/SheetDataRow";
import {
  useGetSheetFieldSchemas,
  useSetSheetFieldSchema,
} from "../../../../../domain/SheetFieldSchema";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";
import { duplicateRecursiveSheetData } from "../helper";

import {
  createNewDisplayNameOrThrow,
  createNewSheetNameOrThrow,
} from "@/helpers/createNewName";
import { generateId } from "@/utils/generateId";

export const DialogSheetDuplicateSome: React.FC<
  {
    sheets: Sheet[];
    selectedSheetIds: string[];
    setSelectedSheetIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  sheets,
  selectedSheetIds,
  setSelectedSheetIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const setSheet = useSetSheet();
  const setSheetFieldSchema = useSetSheetFieldSchema();
  const setSheetDataRow = useSetSheetDataRow();

  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();

  const { renderDialogs, setIsInProgress, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const progressTotal = selectedSheetIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const runDuplicate = async () => {
    setProgressCount(0);
    setDialogPhase("in_progress");
    const length = sheets.length;
    const displayNames = sheets.map((s) => s.displayName);
    const names = sheets.map((s) => s.name);
    try {
      for (const [index, sheetId] of selectedSheetIds.entries()) {
        const duplicateSheet = sheets.find((c) => c.id === sheetId);
        if (!duplicateSheet) continue;

        const newDisplayName = createNewDisplayNameOrThrow(
          displayNames,
          duplicateSheet.displayName
        );
        const newName = createNewSheetNameOrThrow(names, duplicateSheet.name);

        await duplicateRecursiveSheetData({
          duplicateSheet,
          getSheetDataRows,
          getSheetFieldSchemas,
          setSheetDataRow,
          setSheetFieldSchema,
          setSheet,
          displayName: newDisplayName,
          name: newName,
          sort: length + 1 + index,
          newSheetId: generateId(),
        });
        displayNames.push(newDisplayName);
        names.push(newName);
        setProgressCount((cnt) => cnt + 1);
      }
      setSelectedSheetIds([]);
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "duplicate",
    targetType: "sheet",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setSelectedSheetIds([]);
          },
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: { ...messages.success },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};
