import {
  PermissionsAction,
  PermissionsKey,
  PermissionsRaw,
  PermissionsWithIdKey,
  PermissionsWithIdsKey,
  RolePermissions,
  permissionsActions,
  permissionsKeys,
  permissionsWithIdKeys,
  permissionsWithIdsKeys,
} from "../domain/roles";

export const parsePermissions = (
  permissions: PermissionsRaw
): RolePermissions => {
  const result: RolePermissions = {
    organizations: {
      read: false,
      write: false,
    },
    members: {
      read: false,
      write: false,
      delete: false,
    },
    roles: {
      read: false,
      write: false,
      delete: false,
    },
    version_categories: {
      read: false,
      write: false,
      delete: false,
    },
    algorithm_versions: {
      read: false,
    },
    view_masters: {
      read: false,
    },
    connect_masters: {
      read: false,
    },
    connects: {
      read: false,
      write: false,
      delete: false,
    },
    connect_jobs: {
      write: false,
    },
    connect_job_histories: {
      read: false,
    },
    optimize: {
      write: false,
    },
    evaluate: {
      write: false,
    },
    versions: {
      read: false,
      write: false,
      delete: false,
    },
    version_category: { read: [], write: [], delete: [] },
    versions_version_category: { read: [], write: [], delete: [] },
    sheet: { read: [], write: [], delete: [] },
    version: { read: [], write: [], delete: [] },
    version_sheet: { read: [], write: [], delete: [] },
  };

  if (!permissions) {
    return result;
  }
  for (const perm of permissions) {
    const strs = perm.split(":");
    if (strs.length === 1) {
      throw new Error("不正なパーミッションが指定されました: " + perm);
    }
    if (strs.length === 2) {
      const [permKey, action] = strs;
      if (!permissionsActions.includes(action as PermissionsAction)) {
        throw new Error("不正なアクションが指定されました: " + perm);
      }
      if (permissionsKeys.includes(permKey as PermissionsKey)) {
        setPermissions(
          result,
          permKey as PermissionsKey,
          action as PermissionsAction
        );
      } else {
        throw new Error("不正なパーミッションが指定されました: " + perm);
      }
    }
    if (strs.length === 3) {
      const [permKey, action, id] = strs;
      if (!permissionsWithIdKeys.includes(permKey as PermissionsWithIdKey)) {
        throw new Error("不正なパーミッションが指定されました: " + perm);
      }
      setPermissionsWithId(
        result,
        permKey as PermissionsWithIdKey,
        action as PermissionsAction,
        id
      );
    }
    if (strs.length === 4) {
      const [permKey, action, versionId, sheetId] = strs;
      if (!permissionsWithIdsKeys.includes(permKey as PermissionsWithIdsKey)) {
        throw new Error("不正なパーミッションが指定されました: " + perm);
      }
      setVersionSheetPermissions(
        result,
        action as PermissionsAction,
        versionId,
        sheetId
      );
    }
  }

  return result;
};

const setPermissions = (
  result: RolePermissions,
  permissionsKey: PermissionsKey,
  action: PermissionsAction
) => {
  switch (action) {
    case "read":
      result[permissionsKey].read = true;
      break;
    case "write":
      result[permissionsKey].write = true;
      break;
    case "delete":
      result[permissionsKey].delete = true;
      break;
    default:
      throw new Error("不正なアクションが指定されました");
  }
};

const setPermissionsWithId = (
  result: RolePermissions,
  permissionsKey: PermissionsWithIdKey,
  action: (typeof permissionsActions)[number],
  id: string
) => {
  switch (action) {
    case "read":
      result[permissionsKey].read.push(id);
      break;
    case "write":
      result[permissionsKey].write.push(id);
      break;
    case "delete":
      result[permissionsKey].delete.push(id);
      break;
    default:
      throw new Error("不正なアクションが指定されました");
  }
};

const setVersionSheetPermissions = (
  result: RolePermissions,
  action: (typeof permissionsActions)[number],
  versionId: string,
  sheetId: string
) => {
  switch (action) {
    case "read":
      result.version_sheet.read.push({
        versionId,
        sheetId,
      });
      break;
    case "write":
      result.version_sheet.write.push({
        versionId,
        sheetId,
      });
      break;
    case "delete":
      result.version_sheet.delete.push({
        versionId,
        sheetId,
      });
      break;
    default:
      throw new Error("不正なアクションが指定されました");
  }
};

export const convertToPermissionsRaw = (
  permissions: RolePermissions
): PermissionsRaw => {
  const result: PermissionsRaw = [];
  for (const [permKey, permValue] of Object.entries(permissions)) {
    if (permissionsWithIdKeys.includes(permKey as PermissionsWithIdKey)) {
      for (const action of permissionsActions) {
        for (const id of permissions[permKey as PermissionsWithIdKey][action]) {
          result.push(`${permKey}:${action}:${id}`);
        }
      }
    } else if (
      permissionsWithIdsKeys.includes(permKey as PermissionsWithIdsKey)
    ) {
      for (const action of permissionsActions) {
        for (const { versionId, sheetId } of permissions[
          permKey as PermissionsWithIdsKey
        ][action]) {
          result.push(`${permKey}:${action}:${versionId}:${sheetId}`);
        }
      }
    } else {
      for (const [action, isPermitted] of Object.entries(permValue)) {
        if (isPermitted) {
          result.push(`${permKey}:${action}`);
        }
      }
    }
  }
  return result;
};
