import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { DeliveryPlanSchemaType } from "./types";

type DeliveryPlanDependSheets = ViewTypeDependSheet<
  keyof DeliveryPlanSchemaType,
  {
    [k in keyof DeliveryPlanSchemaType]: keyof DeliveryPlanSchemaType[k] &
      string;
  }
>;

export const viewTypeShippingPlanSheet: DeliveryPlanDependSheets = {
  key: "shippingPlan",
  displayName: "出荷計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shippingDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

export const viewTypeSalesInputSheet: DeliveryPlanDependSheets = {
  key: "salesInput",
  displayName: "販売計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shipmentDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "decidedFlag",
      displayName: "販売確定フラグ",
      type: "boolean",
    },
    {
      key: "shippingFlag",
      displayName: "出荷フラグ",
      type: "boolean",
    },
    {
      key: "priority",
      displayName: "優先度",
      type: "float",
    },
    {
      key: "comment",
      displayName: "コメント",
      type: "string",
    },
  ],
};

const viewTypeItemMasterSheet: DeliveryPlanDependSheets = {
  key: "itemMaster",
  displayName: "品目マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
    {
      key: "groupName",
      displayName: "グループ名",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ",
      type: "string",
    },
  ],
};

export const viewStorageItemMasterSheet: DeliveryPlanDependSheets = {
  key: "storageItemMaster",
  displayName: "保管所品目マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
  ],
};

export const viewTypeBatchTaskPlanSheet: DeliveryPlanDependSheets = {
  key: "batchTaskPlan",
  displayName: "バッチタスク計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "処理開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskStartDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskEndDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "処理終了時刻",
      type: "date-time",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "isHighlighted",
      displayName: "ハイライト",
      type: "boolean",
    },
    {
      key: "comment",
      displayName: "コメント",
      type: "string",
    },
  ],
};

export const viewTypePurchasePlanSheet: DeliveryPlanDependSheets = {
  key: "purchasePlan",
  displayName: "原料購買計画",
  dependSheetFields: [
    {
      key: "orderDate",
      displayName: "発注日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "supplierName",
      displayName: "仕入先",
      type: "string",
    },
    {
      key: "dueDate",
      displayName: "納期",
      type: "date",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "toStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

export const viewTypeBusinessDayCalendarSheet: DeliveryPlanDependSheets = {
  key: "businessDayCalendar",
  displayName: "営業日",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
  ],
};

export const viewTypeAdjustmentInputSheet: DeliveryPlanDependSheets = {
  key: "adjustmentInput",
  displayName: "在庫実績入力",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
  ],
};

export const viewTypeBatchTaskMasterSheet: DeliveryPlanDependSheets = {
  key: "batchTaskMaster",
  displayName: "バッチタスクマスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "deviceName",
      displayName: "使用装置",
      type: "string",
    },
    {
      key: "mainItemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "quantityPerBatch",
      displayName: "1バッチにおける生成量",
      type: "float",
    },
    {
      key: "bomVersion",
      displayName: "BOMのバージョン指定",
      type: "string",
    },
    {
      key: "preProcessMinutes",
      displayName: "前処理時間",
      type: "integer",
    },
    {
      key: "postProcessMinutes",
      displayName: "後処理時間",
      type: "integer",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
    {
      key: "isIntoStoragePerBatches",
      displayName: "バッチ毎在庫反映",
      type: "boolean",
    },
    {
      key: "leadTime",
      displayName: "リードタイム",
      type: "integer",
    },
    {
      key: "nonBusinessDayTaskAllowed",
      displayName: "非営業日タスク実行可否",
      type: "boolean",
    },
  ],
};

export const viewTypeParamsSheet: DeliveryPlanDependSheets = {
  key: "params",
  displayName: "最適化パラメータ",
  dependSheetFields: [
    {
      key: "evaluateStartDate",
      displayName: "評価を開始する日",
      type: "date",
    },
    {
      key: "evaluateEndDate",
      displayName: "評価を終了する日",
      type: "date",
    },
    {
      key: "optimizeStartDate",
      displayName: "自動ドラフトを開始する日",
      type: "date",
    },
    {
      key: "optimizeEndDate",
      displayName: "自動ドラフトを終了する日",
      type: "date",
    },
    {
      key: "switchingCostToPenaltyRate",
      displayName: "切替コストとペナルティの比率",
      type: "float",
    },
    {
      key: "offsetMinutes",
      displayName: "始業時刻 (min)",
      type: "integer",
    },
  ],
};

export const ViewTypeBatchTaskStoragePlanSheet: DeliveryPlanDependSheets = {
  key: "batchTaskStoragePlan",
  displayName: "バッチタスク生成品保管計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "toStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const ViewTypeBatchTaskRawMaterialPlanSheet: DeliveryPlanDependSheets = {
  key: "batchTaskRawMaterialPlan",
  displayName: "バッチタスク原料計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "原料名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "fromStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const viewTypeDeviceMasterSheet: DeliveryPlanDependSheets = {
  key: "deviceMaster",
  displayName: "装置マスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "integer",
    },
    {
      key: "deviceName",
      displayName: "装置名",
      type: "string",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
  ],
};

const viewTypeBatchTaskProcessTimeMasterSheet: DeliveryPlanDependSheets = {
  key: "batchTaskProcessTimeMaster",
  displayName: "バッチタスク所要時間マスタ",
  dependSheetFields: [
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "processMinutes",
      displayName: "処理時間",
      type: "integer",
    },
  ],
};

const viewTypeSwitchingBatchTaskMasterSheet: DeliveryPlanDependSheets = {
  key: "switchingBatchTaskMaster",
  displayName: "バッチタスク切り替えマスタ",
  dependSheetFields: [
    {
      key: "batchTaskNameBefore",
      displayName: "切り替え前",
      type: "string",
    },
    {
      key: "batchTaskNameAfter",
      displayName: "切り替え後",
      type: "string",
    },
    {
      key: "switchingMinutes",
      displayName: "切り替え時間",
      type: "integer",
    },
    {
      key: "switchingCost",
      displayName: "切り替え費用",
      type: "float",
    },
  ],
};

const viewTypeRawMaterialMasterSheet: DeliveryPlanDependSheets = {
  key: "rawMaterialMaster",
  displayName: "原料マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目名",
      type: "string",
    },
    {
      key: "supplierName",
      displayName: "仕入れ先名",
      type: "string",
    },
    {
      key: "leadTime",
      displayName: "リードタイム（分）",
      type: "integer",
    },
    {
      key: "lowerBoundStock",
      displayName: "発注点在庫",
      type: "float",
    },
    {
      key: "quantity",
      displayName: "発注量",
      type: "float",
    },
    {
      key: "price",
      displayName: "価格",
      type: "float",
    },
  ],
};

const viewTypeScoreSummariesSheet: DeliveryPlanDependSheets = {
  key: "scoreSummaries",
  displayName: "最適化スコアサマリ",
  dependSheetFields: [
    {
      key: "name",
      displayName: "名称",
      type: "string",
    },
    {
      key: "parent",
      displayName: "親サマリ",
      type: "string",
    },
    {
      key: "value",
      displayName: "値",
      type: "string",
    },
    {
      key: "value_type",
      displayName: "データ型",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "is_higher_rated",
      displayName: "評価の高低",
      type: "boolean",
    },
    {
      key: "sort",
      displayName: "ソート順",
      type: "integer",
    },
  ],
};

export const viewTypeDeliveryPlanDependSheets: DeliveryPlanDependSheets[] = [
  viewTypeShippingPlanSheet,
  viewTypeSalesInputSheet,
  viewTypeItemMasterSheet,
  viewStorageItemMasterSheet,
  viewTypeParamsSheet,
  viewTypeAdjustmentInputSheet,
  viewTypeBatchTaskPlanSheet,
  viewTypeBatchTaskMasterSheet,
  viewTypeBusinessDayCalendarSheet,
  viewTypeBatchTaskProcessTimeMasterSheet,
  viewTypeDeviceMasterSheet,
  viewTypePurchasePlanSheet,
  ViewTypeBatchTaskRawMaterialPlanSheet,
  ViewTypeBatchTaskStoragePlanSheet,
  viewTypeSwitchingBatchTaskMasterSheet,
  viewTypeRawMaterialMasterSheet,
  viewTypeScoreSummariesSheet,
];
