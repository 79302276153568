import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import "ress";
import "./fluentInit";

const root = createRoot(
  document.body.appendChild(document.createElement("div"))
);

const dynamicImport = async () => {
  const isDev = process.env.APP_ENV === "development";
  const isStg = process.env.APP_ENV === "staging";
  const isSand = process.env.APP_ENV === "sandbox";
  if (isDev || isStg || isSand) {
    const { EnvMessage } = await import("./utils/envMessage");
    return <EnvMessage isDev={isDev} />;
  }
};

dynamicImport().then((EnvMessage) => {
  EnvMessage
    ? root.render(
        <>
          <App />
          {EnvMessage}
        </>
      )
    : root.render(<App />);
});
