import { useEffect, useState } from "react";

import { useAppRouteParams } from "@/AppRoutes";
import { Sheet, useGetSheet } from "@/domain/Sheet";
import {
  useAuthorizedSheets,
  useGetAuthorizedSheet,
} from "./useAuthorizedCollections";

// 参照先のシートのリストを取得するカスタムフック
export const useReferencedSheets = () => {
  const { versionId } = useAppRouteParams();
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);
  const [referencedSheets, setReferencedSheets] = useState<
    (Sheet & { refVersionId: string })[]
  >([]);
  const getSheet = useGetAuthorizedSheet();

  useEffect(() => {
    (async () => {
      const referencedSheets: (Sheet & { refVersionId: string })[] = [];
      for (const sheet of sheets) {
        if (!sheet.referTo) {
          continue;
        }
        const referencedSheet = await getSheet({
          versionId: sheet.referTo.versionId,
          sheetId: sheet.referTo.sheetId,
        });
        if (referencedSheet) {
          referencedSheets.push({
            ...referencedSheet,
            refVersionId: sheet.referTo.versionId,
          });
        }
      }
      setReferencedSheets(referencedSheets);
    })();
  }, [sheets, getSheet]);

  return referencedSheets;
};
