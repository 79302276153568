import { ViewType } from "../domain/ViewType";

import { viewTypesHKRShip } from "@/viewType/viewTypes/Optium/hkrShip/views";
import { viewTypesCptDom } from "./viewTypes/Optium/cptDom/views";
import { viewTypesNshKws } from "./viewTypes/Optium/nshKws/views";
import { viewTypesPlaniumChemical } from "./viewTypes/Planium/Chemical/views";
import { viewTypesPlaniumChemicalDemo } from "./viewTypes/Planium/ChemicalDemo/views";
import { viewTypeDemoPlantLines } from "./viewTypes/Platform/demoPlant/demoPlantLines";
import { viewTypeDemoPlantStockGraph } from "./viewTypes/Platform/demoPlant/demoPlantStockGraph";
import { viewTypeDemoShipView } from "./viewTypes/Platform/demoShip/demoShipView";
import { viewTypeExample } from "./viewTypes/Platform/example";
import { viewTypeMatrix } from "./viewTypes/Platform/matrix";
import { viewTypeMatrixMultiType } from "./viewTypes/Platform/matrixMultiType";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypes: ViewType<any, any>[] = [
	viewTypeExample,
	viewTypeDemoPlantLines,
	viewTypeDemoPlantStockGraph,
	viewTypeMatrix,
	viewTypeMatrixMultiType,
	viewTypeDemoShipView,
	...viewTypesPlaniumChemicalDemo,
	...viewTypesPlaniumChemical,
	...viewTypesHKRShip,
	...viewTypesNshKws,
	...viewTypesCptDom,
];
