import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { useAPI } from "../hooks";

import { ViewMasterRaw } from "@/domain/ViewMaster";

const viewTypesCtx = createContext<{
  availableViewTypes: ViewMasterRaw[] | null;
  runReloadAvailableViewTypes: () => Promise<void>;
  isLoadedAvailableViewTypes: boolean;
}>({
  availableViewTypes: [],
  runReloadAvailableViewTypes: async () => {
    // nop
  },
  isLoadedAvailableViewTypes: false,
});

// 組織ごとに利用可能なビュータイプを提供するプロバイダ
export const ViewTypesProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: availableViewTypes,
    runReloadState: runReloadAvailableViewTypes,
    isLoadedState: isLoadedAvailableViewTypes,
  } = useAPI<ViewMasterRaw[]>("view_masters");

  const viewTypesCtxValue = useMemo(
    () => ({
      availableViewTypes,
      runReloadAvailableViewTypes,
      isLoadedAvailableViewTypes,
    }),
    [
      availableViewTypes,
      runReloadAvailableViewTypes,
      isLoadedAvailableViewTypes,
    ]
  );

  return (
    <viewTypesCtx.Provider value={viewTypesCtxValue}>
      {children}
    </viewTypesCtx.Provider>
  );
};

export const useAvailableViewTypes = () => {
  return useContext(viewTypesCtx);
};
