import { ViewType } from "@/domain/ViewType";
import { viewTypeNshKws2sGanttChart } from "@/viewType/viewTypes/Optium/nshKws/2sGanttChart";
import { viewTypeNshKws4sGanttChart } from "@/viewType/viewTypes/Optium/nshKws/views/4sGanttChart";
import { viewTypeNshKwsCSVView1s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView1s";
import { viewTypeNshKwsCSVView2sEast } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView2sEast";
import { viewTypeNshKwsCSVView2sEid } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView2sEid";
import { viewTypeNshKwsCSVView3s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView3s";
import { viewTypeNshKwsCSVView4s } from "@/viewType/viewTypes/Optium/nshKws/views/CSVView4s";
import { viewTypeNshKwsCSVViewFinancial } from "@/viewType/viewTypes/Optium/nshKws/views/CSVViewFinancial";
import { viewTypeNshKwsCSVViewManagement } from "@/viewType/viewTypes/Optium/nshKws/views/CSVViewManagement";
import { viewTypeNshKwsSpreadSheetAndGraph } from "@/viewType/viewTypes/Optium/nshKws/views/spreadSheetAndGraph";

/**
 * @description 各ビューの定義を列挙する。
 * 新しくviewを定義したらここに追加する
 */
export const viewTypesNshKws: ViewType<any, any>[] = [
  viewTypeNshKwsSpreadSheetAndGraph,
  viewTypeNshKws4sGanttChart,
  viewTypeNshKws2sGanttChart,
  viewTypeNshKwsCSVViewManagement,
  viewTypeNshKwsCSVView1s,
  viewTypeNshKwsCSVView2sEid,
  viewTypeNshKwsCSVView2sEast,
  viewTypeNshKwsCSVView3s,
  viewTypeNshKwsCSVView4s,
  viewTypeNshKwsCSVViewFinancial,
];
