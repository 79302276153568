import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { AppViewMasterRaw } from "../../../domain/AppViewMasters";

import { viewTypes } from "@/viewType";
import { VIEW_TYPE_KEY } from "@/viewType/constants";
import { useAdminAPI } from "../hooks";

const ctx = createContext<{
  appViewMasters: AppViewMasterRaw[] | null;
  runReloadAppViewMasters: () => Promise<void>;
  isLoadedAppViewMasters: boolean;
  invalidViewsOnKey: AppViewMasterRaw[];
}>({
  appViewMasters: null,
  runReloadAppViewMasters: async () => {
    //nop
  },
  isLoadedAppViewMasters: false,
  invalidViewsOnKey: [],
});

export const AdminAppViewMastersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const {
    state: appViewMasters,
    runReloadState: runReloadAppViewMasters,
    isLoadedState: isLoadedAppViewMasters,
  } = useAdminAPI<AppViewMasterRaw[]>("appViewMasters");

  const invalidViewsOnKey = useMemo(() => {
    const keysOnViewTypes = viewTypes.map((viewType) => viewType.key);
    return (
      appViewMasters?.filter(
        (view) => !keysOnViewTypes.includes(view.view_type as VIEW_TYPE_KEY)
      ) ?? []
    );
  }, [appViewMasters]);

  const ctxValue = useMemo(
    () => ({
      appViewMasters,
      runReloadAppViewMasters,
      isLoadedAppViewMasters,
      invalidViewsOnKey,
    }),
    [
      appViewMasters,
      runReloadAppViewMasters,
      isLoadedAppViewMasters,
      invalidViewsOnKey,
    ]
  );

  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export const useAdminAppViewMasters = () => {
  return useContext(ctx);
};
