import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import React from "react";

import { AppAccountProvider } from "./AppAccountProvider";
import { AppAuthProvider } from "./AppAuthProvider";
import { AppNotificationProvider } from "./AppNotificationProvider";
import { AppOrgsAndInvitedOrgsProvider } from "./AppOrgsAndInvitedOrgsProvider";

import { lightTheme } from "@/themes";

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <FluentProvider theme={lightTheme}>
      <AppAuthProvider>
        <AppAccountProvider>
          <AppOrgsAndInvitedOrgsProvider>
            <AppNotificationProvider>{children}</AppNotificationProvider>
          </AppOrgsAndInvitedOrgsProvider>
        </AppAccountProvider>
      </AppAuthProvider>
    </FluentProvider>
  );
};
