import { ViewTypeDependSheet } from "@/domain/ViewTypeDependSheet";
import { StockDependSheetsType } from "./types";

type StockDependSheets = ViewTypeDependSheet<
  keyof StockDependSheetsType,
  {
    [k in keyof StockDependSheetsType]: keyof StockDependSheetsType[k] & string;
  }
>;

export const viewTypeBatchTaskPlanSheet: StockDependSheets = {
  key: "batchTaskPlan",
  displayName: "バッチタスク計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "処理開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskStartDateTime",
      displayName: "開始時刻",
      type: "date-time",
    },
    {
      key: "batchTaskEndDateTime",
      displayName: "終了時刻",
      type: "date-time",
    },
    {
      key: "endDateTime",
      displayName: "処理終了時刻",
      type: "date-time",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "isHighlighted",
      displayName: "ハイライト",
      type: "boolean",
    },
    {
      key: "comment",
      displayName: "コメント",
      type: "string",
    },
  ],
};

export const viewTypeShippingPlanSheet: StockDependSheets = {
  key: "shippingPlan",
  displayName: "出荷計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shippingDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

export const viewTypePurchasePlanSheet: StockDependSheets = {
  key: "purchasePlan",
  displayName: "原料購買計画",
  dependSheetFields: [
    {
      key: "orderDate",
      displayName: "発注日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "supplierName",
      displayName: "仕入先",
      type: "string",
    },
    {
      key: "dueDate",
      displayName: "納期",
      type: "date",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "toStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

export const viewTypeBusinessDayCalendarSheet: StockDependSheets = {
  key: "businessDayCalendar",
  displayName: "営業日",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
  ],
};

export const viewTypeAdjustmentInputSheet: StockDependSheets = {
  key: "adjustmentInput",
  displayName: "在庫実績入力",
  dependSheetFields: [
    {
      key: "date",
      displayName: "日付",
      type: "date",
    },
    {
      key: "storageName",
      displayName: "保管場所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
  ],
};

export const viewTypeSalesInputSheet: StockDependSheets = {
  key: "salesInput",
  displayName: "販売計画",
  dependSheetFields: [
    {
      key: "salesId",
      displayName: "販売ID",
      type: "string",
    },
    {
      key: "shipmentDate",
      displayName: "出荷日",
      type: "date",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "shippingFlag",
      displayName: "出荷フラグ",
      type: "boolean",
    },
  ],
};

export const viewTypeBatchTaskMasterSheet: StockDependSheets = {
  key: "batchTaskMaster",
  displayName: "バッチタスクマスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "string",
    },
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "deviceName",
      displayName: "使用装置",
      type: "string",
    },
    {
      key: "mainItemName",
      displayName: "主品目",
      type: "string",
    },
    {
      key: "quantityPerBatch",
      displayName: "1バッチにおける生成量",
      type: "float",
    },
    {
      key: "bomVersion",
      displayName: "BOMのバージョン指定",
      type: "string",
    },
    {
      key: "preProcessMinutes",
      displayName: "前処理時間",
      type: "integer",
    },
    {
      key: "postProcessMinutes",
      displayName: "後処理時間",
      type: "integer",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
    {
      key: "isIntoStoragePerBatches",
      displayName: "バッチ毎在庫反映",
      type: "boolean",
    },
    {
      key: "leadTime",
      displayName: "リードタイム",
      type: "integer",
    },
    {
      key: "nonBusinessDayTaskAllowed",
      displayName: "非営業日タスク実行可否",
      type: "boolean",
    },
  ],
};

export const viewTypeParamsSheet: StockDependSheets = {
  key: "params",
  displayName: "最適化パラメータ",
  dependSheetFields: [
    {
      key: "evaluateStartDate",
      displayName: "評価を開始する日",
      type: "date",
    },
    {
      key: "evaluateEndDate",
      displayName: "評価を終了する日",
      type: "date",
    },
    {
      key: "optimizeStartDate",
      displayName: "自動ドラフトを開始する日",
      type: "date",
    },
    {
      key: "optimizeEndDate",
      displayName: "自動ドラフトを終了する日",
      type: "date",
    },
    {
      key: "offsetMinutes",
      displayName: "始業時刻 (min)",
      type: "integer",
    },
  ],
};

export const ViewTypeBatchTaskStoragePlanSheet: StockDependSheets = {
  key: "batchTaskStoragePlan",
  displayName: "バッチタスク生成品保管計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "toStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const ViewTypeBatchTaskRawMaterialPlanSheet: StockDependSheets = {
  key: "batchTaskRawMaterialPlan",
  displayName: "バッチタスク原料計画",
  dependSheetFields: [
    {
      key: "batchTaskId",
      displayName: "バッチタスクID",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "原料名",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "数量",
      type: "float",
    },
    {
      key: "fromStorageName",
      displayName: "保管場所",
      type: "string",
    },
  ],
};

const viewTypeItemMasterSheet: StockDependSheets = {
  key: "itemMaster",
  displayName: "品目マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
    {
      key: "groupName",
      displayName: "グループ名",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "itemStorageType",
      displayName: "品目保管所タイプ",
      type: "string",
    },
  ],
};

const viewTypeDeviceMasterSheet: StockDependSheets = {
  key: "deviceMaster",
  displayName: "装置マスタ",
  dependSheetFields: [
    {
      key: "id",
      displayName: "id",
      type: "integer",
    },
    {
      key: "deviceName",
      displayName: "装置名",
      type: "string",
    },
    {
      key: "taskDeviceType",
      displayName: "タスク装置タイプ",
      type: "string",
    },
  ],
};

const viewTypeBatchTaskProcessTimeMasterSheet: StockDependSheets = {
  key: "batchTaskProcessTimeMaster",
  displayName: "バッチタスク所要時間マスタ",
  dependSheetFields: [
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "batchCount",
      displayName: "バッチ数",
      type: "integer",
    },
    {
      key: "processMinutes",
      displayName: "処理時間",
      type: "integer",
    },
  ],
};

// TODO: switchingPenalty の追加
// TODO: 切替時間は integer が正しいが sheet のデータが float なので一旦 float にしている
const viewTypeSwitchingBatchTaskMasterSheet: StockDependSheets = {
  key: "switchingBatchTaskMaster",
  displayName: "切替バッチタスクマスタ",
  dependSheetFields: [
    {
      key: "batchTaskNameBefore",
      displayName: "前バッチタスク名",
      type: "string",
    },
    {
      key: "batchTaskNameAfter",
      displayName: "後バッチタスク名",
      type: "string",
    },
    {
      key: "switchingMinutes",
      displayName: "切替時間",
      type: "float",
    },
    {
      key: "switchingCost",
      displayName: "切替費用",
      type: "float",
    },
  ],
};

const viewTypeUtilityCostTypeMasterSheet: StockDependSheets = {
  key: "utilityCostTypeMaster",
  displayName: "ユーティリティコストタイプマスタ",
  dependSheetFields: [
    {
      key: "utilityCostType",
      displayName: "ユーティリティコストタイプ",
      type: "string",
    },
    {
      key: "costUnit",
      displayName: "コスト単位",
      type: "string",
    },
    {
      key: "scoreCoefficient",
      displayName: "スコア係数",
      type: "float",
    },
    {
      key: "limitPerDay",
      displayName: "日限度",
      type: "float",
    },
  ],
};

export const viewTypeBatchTaskUtilityMasterSheet: StockDependSheets = {
  key: "batchTaskUtilityMaster",
  displayName: "バッチタスクユーティリティマスタ",
  dependSheetFields: [
    {
      key: "batchTaskName",
      displayName: "バッチタスク名",
      type: "string",
    },
    {
      key: "utilityType",
      displayName: "ユーティリティタイプ",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "バッチごとの生成量",
      type: "float",
    },
  ],
};

const viewTypeDeviceMaintenanceInputSheet: StockDependSheets = {
  key: "deviceMaintenanceInput",
  displayName: "装置メンテナンス計画",
  dependSheetFields: [
    {
      key: "deviceName",
      displayName: "装置名",
      type: "string",
    },
    {
      key: "startDateTime",
      displayName: "開始日",
      type: "date",
    },
    {
      key: "endDateTime",
      displayName: "終了日",
      type: "date",
    },
  ],
};

export const viewStorageMasterSheet: StockDependSheets = {
  key: "storageMaster",
  displayName: "保管所マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所名",
      type: "string",
    },
    {
      key: "unit",
      displayName: "単位",
      type: "string",
    },
    {
      key: "lowerCapacity",
      displayName: "下限容量",
      type: "float",
    },
    {
      key: "upperCapacity",
      displayName: "上限容量",
      type: "float",
    },
  ],
};

export const viewStockCriteriaMasterSheet: StockDependSheets = {
  key: "stockCriteriaMaster",
  displayName: "在庫基準マスタ",
  dependSheetFields: [
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
    {
      key: "criteriaName",
      displayName: "基準名",
      type: "string",
    },
    {
      key: "upperOrLower",
      displayName: "上限/下限",
      type: "string",
    },
    {
      key: "quantity",
      displayName: "基準数量",
      type: "float",
    },
  ],
};

export const viewStorageItemMasterSheet: StockDependSheets = {
  key: "storageItemMaster",
  displayName: "保管所品目マスタ",
  dependSheetFields: [
    {
      key: "storageName",
      displayName: "保管所",
      type: "string",
    },
    {
      key: "itemName",
      displayName: "品目",
      type: "string",
    },
  ],
};

export const viewTypeStockDependSheets: StockDependSheets[] = [
  viewTypeBatchTaskPlanSheet,
  viewTypeShippingPlanSheet,
  viewTypePurchasePlanSheet,
  viewTypeBusinessDayCalendarSheet,
  viewTypeAdjustmentInputSheet,
  viewTypeSalesInputSheet,
  viewTypeBatchTaskMasterSheet,
  viewTypeParamsSheet,
  ViewTypeBatchTaskStoragePlanSheet,
  ViewTypeBatchTaskRawMaterialPlanSheet,
  viewTypeItemMasterSheet,
  viewTypeDeviceMasterSheet,
  viewTypeBatchTaskProcessTimeMasterSheet,
  viewTypeSwitchingBatchTaskMasterSheet,
  viewTypeUtilityCostTypeMasterSheet,
  viewTypeBatchTaskUtilityMasterSheet,
  viewTypeDeviceMaintenanceInputSheet,
  viewStorageMasterSheet,
  viewStockCriteriaMasterSheet,
  viewStorageItemMasterSheet,
];
