import loadable from "@loadable/component";

import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsSpreadSheetAndGraph: StrictViewType = {
  key: "nshKwsSpreadSheetAndGraph",
  displayName: "NSH-KWS スプレッドシート",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "setting",
      displayName: "setting",
      dependSheetFields: [
        {
          key: "optimizationStartDay",
          displayName: "最適化開始日",
          type: "string",
        },
        {
          key: "optimizationEndDay",
          displayName: "最適化終了日",
          type: "string",
        },
        {
          key: "optimizationGeneral",
          displayName: "最適化_general",
          type: "boolean",
        },
        {
          key: "optimizationEo",
          displayName: "最適化_eo",
          type: "boolean",
        },
        {
          key: "optimizationEg",
          displayName: "最適化_eg",
          type: "boolean",
        },
        {
          key: "optimizationNvp",
          displayName: "最適化_nvp",
          type: "boolean",
        },
        {
          key: "optimizationEa",
          displayName: "最適化_ea",
          type: "boolean",
        },
        {
          key: "optimizationEi",
          displayName: "最適化_ei",
          type: "boolean",
        },
        {
          key: "optimizationBhea",
          displayName: "最適化_bhea",
          type: "boolean",
        },
        {
          key: "optimizationEoa",
          displayName: "最適化_eoa",
          type: "boolean",
        },
        {
          key: "optimizationSft",
          displayName: "最適化_sft",
          type: "boolean",
        },
        {
          key: "optimization4s",
          displayName: "最適化_4s",
          type: "boolean",
        },
        {
          key: "optimization4sNoTank",
          displayName: "最適化_4s_タンク無し製品",
          type: "boolean",
        },
        {
          key: "timeCoefficient",
          displayName: "実行時間係数",
          type: "float",
        },
        {
          key: "maxDaysToDivideCalculation",
          displayName: "分割計算時最大分割間隔日数",
          type: "integer",
        },
        {
          key: "maxDaysToOptimizeCalculation",
          displayName: "分割計算時最大最適化日数",
          type: "integer",
        },
      ],
    },
    {
      key: "parameter",
      displayName: "parameter",
      dependSheetFields: [
        {
          key: "nonEgRateChidori",
          displayName: "非EG化率_千鳥",
          type: "float",
        },
        {
          key: "nonEgRateUkishima",
          displayName: "非EG化率_浮島",
          type: "float",
        },
        {
          key: "egYieldChidori",
          displayName: "MEG-Y計算用EG収率_千鳥",
          type: "float",
        },
        {
          key: "egYieldUkishima",
          displayName: "MEG-Y計算用EG収率_浮島",
          type: "float",
        },
        {
          key: "megRateChidori",
          displayName: "MEG-Y計算用MEG率_千鳥",
          type: "float",
        },
        {
          key: "megRateUkishima",
          displayName: "MEG-Y計算用MEG率_浮島",
          type: "float",
        },
        {
          key: "etUtilizationRateChidori",
          displayName: "Et利用率_千鳥",
          type: "float",
        },
        {
          key: "catalystAmountChidori",
          displayName: "触媒量_千鳥",
          type: "float",
        },
        {
          key: "feedWaterTemperatureChidori",
          displayName: "給水温度_千鳥",
          type: "float",
        },
        {
          key: "gasEnthalpyChidori",
          displayName: "gasエンタルピー_千鳥",
          type: "float",
        },
        {
          key: "ceSChidori",
          displayName: "CE_S_千鳥",
          type: "float",
        },
        {
          key: "ceTChidori",
          displayName: "CE_T_千鳥",
          type: "float",
        },
        {
          key: "steamCorrectionChidori",
          displayName: "蒸気補正値_千鳥",
          type: "float",
        },
        {
          key: "steamCorrectionEa",
          displayName: "EA用蒸気補正値",
          type: "float",
        },
        {
          key: "totalEgSteamConsumptionChidori",
          displayName: "全量EO期間EG消費蒸気_千鳥",
          type: "float",
        },
        {
          key: "eoStopCostPerDayChidori",
          displayName: "EO停止コスト1日あたり単価_千鳥",
          type: "float",
        },
        {
          key: "eoStopCostRestartChidori",
          displayName: "EO停止コスト再スタート単価_千鳥",
          type: "float",
        },
        {
          key: "etUtilizationRateUkishima",
          displayName: "Et利用率_浮島",
          type: "float",
        },
        {
          key: "catalystAmountUkishima",
          displayName: "触媒量_浮島",
          type: "float",
        },
        {
          key: "feedWaterTemperatureUkishima",
          displayName: "給水温度_浮島",
          type: "float",
        },
        {
          key: "gasEnthalpyUkishima",
          displayName: "gasエンタルピー_浮島",
          type: "float",
        },
        {
          key: "ceSUkishima",
          displayName: "CE_S_浮島",
          type: "float",
        },
        {
          key: "ceTUkishima",
          displayName: "CE_T_浮島",
          type: "float",
        },
        {
          key: "steamCorrectionUkishima",
          displayName: "蒸気補正値_浮島",
          type: "float",
        },
        {
          key: "totalEgSteamConsumptionUkishima",
          displayName: "全量EO期間EG消費蒸気_浮島",
          type: "float",
        },
        {
          key: "eoStopCostPerDayUkishima",
          displayName: "EO停止コスト1日あたり単価_浮島",
          type: "float",
        },
        {
          key: "eoStopCostRestartUkishima",
          displayName: "EO停止コスト再スタート単価_浮島",
          type: "float",
        },
        {
          key: "score4sLowerLimit",
          displayName: "4製スコア_在庫下限",
          type: "float",
        },
        {
          key: "score4sUpperLimit",
          displayName: "4製スコア_在庫上限",
          type: "float",
        },
        {
          key: "score4sTargetStockMid",
          displayName: "4製スコア_目標在庫期中",
          type: "float",
        },
        {
          key: "score4sTargetStockEnd",
          displayName: "4製スコア_目標在庫期末",
          type: "float",
        },
        {
          key: "score4sEoConsumptionLowerLimit",
          displayName: "4製スコア_EO消費下限",
          type: "float",
        },
        {
          key: "score4sEoConsumptionUpperLimit",
          displayName: "4製スコア_EO消費上限",
          type: "float",
        },
        {
          key: "score4sEoConsumptionEven",
          displayName: "4製スコア_EO消費平準化",
          type: "float",
        },
        {
          key: "score4sSwitchingTimes",
          displayName: "4製スコア_切替回数",
          type: "float",
        },
        {
          key: "sft70And90ContinuousStopDaysLowerLimit",
          displayName: "SFT70_and_90_連続停止日数下限",
          type: "integer",
        },
        {
          key: "sft70And90ContinuousStopDaysUpperLimit",
          displayName: "SFT70_and_90_連続停止日数上限",
          type: "integer",
        },
        {
          key: "sft70ContinuousOperationDaysLowerLimit",
          displayName: "SFT70_連続稼働日数下限",
          type: "integer",
        },
        {
          key: "sft70ContinuousOperationDaysUpperLimit",
          displayName: "SFT70_連続稼働日数上限",
          type: "integer",
        },
        {
          key: "sft90ContinuousOperationDaysLowerLimit",
          displayName: "SFT90_連続稼働日数下限",
          type: "integer",
        },
        {
          key: "sft90ContinuousOperationDaysUpperLimit",
          displayName: "SFT90_連続稼働日数上限",
          type: "integer",
        },
      ],
    },
    {
      key: "penaltyWeight",
      displayName: "ペナルティ設定",
      dependSheetFields: [
        {
          key: "name",
          displayName: "項目",
          type: "string",
        },
        {
          key: "penaltyCoefficient",
          displayName: "ペナルティ倍率",
          type: "float",
        },
      ],
    },
    {
      key: "catalystParameter",
      displayName: "触媒設定",
      dependSheetFields: [
        {
          key: "factory",
          displayName: "工場",
          type: "string",
        },
        {
          key: "catalystId",
          displayName: "触媒ID",
          type: "integer",
        },
        {
          key: "target",
          displayName: "計算対象",
          type: "string",
        },
        {
          key: "startEo",
          displayName: "区間開始積算EO量",
          type: "float",
        },
        {
          key: "coefficientA",
          displayName: "係数A",
          type: "float",
        },
        {
          key: "coefficientB",
          displayName: "係数B",
          type: "float",
        },
        {
          key: "coefficientC",
          displayName: "係数C",
          type: "float",
        },
      ],
    },
    {
      key: "transportMaster123s",
      displayName: "transport_master_123s",
      dependSheetFields: [
        {
          key: "item",
          displayName: "製品",
          type: "string",
        },
        {
          key: "sourceTank",
          displayName: "移送元タンク名",
          type: "string",
        },
        {
          key: "destinationTank",
          displayName: "移送先タンク名",
          type: "string",
        },
        {
          key: "reverseDirection",
          displayName: "逆方向への移送",
          type: "boolean",
        },
        {
          key: "basicPenalty",
          displayName: "基本ペナルティ",
          type: "float",
        },
        {
          key: "variablePenalty",
          displayName: "従量ペナルティ",
          type: "float",
        },
        {
          key: "isAllTransport",
          displayName: "全量移送要否",
          type: "boolean",
        },
        {
          key: "unitTransportAmount",
          displayName: "単位移送量",
          type: "float",
        },
        {
          key: "holidayOnly",
          displayName: "休日のみ",
          type: "boolean",
        },
      ],
    },
    {
      key: "facilitiesMaster123s",
      displayName: "facilities_master_123s",
      dependSheetFields: [
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
        {
          key: "facilityName",
          displayName: "生産設備名",
          type: "string",
        },
        {
          key: "productionAmountSplit",
          displayName: "生産量分割可否",
          type: "boolean",
        },
        {
          key: "productionPatternMinimumDays",
          displayName: "生産パターン最低維持日数",
          type: "integer",
        },
        {
          key: "acceptanceRatioMinimumDays",
          displayName: "受け入れ割合最低維持日数",
          type: "integer",
        },
        {
          key: "productionPatternChangePenaltyCoefficient",
          displayName: "生産パターン変化ペナルティ係数",
          type: "float",
        },
        {
          key: "acceptanceRatioChangePenaltyCoefficient",
          displayName: "受け入れ割合変化ペナルティ係数",
          type: "float",
        },
      ],
    },
    {
      key: "itemMaster123s",
      displayName: "item_master_123s",
      dependSheetFields: [
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
        {
          key: "itemName",
          displayName: "品目名",
          type: "string",
        },
        {
          key: "isRawMaterialPurchase",
          displayName: "原材料購買フラグ",
          type: "boolean",
        },
        {
          key: "isRawMaterial",
          displayName: "原材料フラグ",
          type: "boolean",
        },
      ],
    },
    {
      key: "itemCategoryMaster123s",
      displayName: "item_category_master_123s",
      dependSheetFields: [
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
        {
          key: "itemCategory",
          displayName: "品目カテゴリ",
          type: "string",
        },
      ],
    },
    {
      key: "egPatternChidori",
      displayName: "EG千鳥生産パターン",
      dependSheetFields: [
        {
          key: "productionPatternSheetId",
          displayName: "生産パターンシートId",
          type: "string",
        },
        {
          key: "facilityPatternName",
          displayName: "生産パターン名",
          type: "string",
        },
        {
          key: "eoConsumption",
          displayName: "加水EO消費量",
          type: "float",
        },
        {
          key: "megWfProduction",
          displayName: "MEG-WF生産量",
          type: "float",
        },
        {
          key: "degProduction",
          displayName: "DEG生産量",
          type: "float",
        },
        {
          key: "triRawMaterial",
          displayName: "Tri原料",
          type: "float",
        },
        {
          key: "triRawMaterialDegConcentration",
          displayName: "Tri原料DEG濃度(0以上1以下)",
          type: "float",
        },
      ],
    },
    {
      key: "egPatternUkishima",
      displayName: "EG浮島生産パターン",
      dependSheetFields: [
        {
          key: "productionPatternSheetId",
          displayName: "生産パターンシートId",
          type: "string",
        },
        {
          key: "facilityPatternName",
          displayName: "生産パターン名",
          type: "string",
        },
        {
          key: "eoConsumption",
          displayName: "加水EO消費量",
          type: "float",
        },
        {
          key: "megWfProduction",
          displayName: "MEG-WF生産量",
          type: "float",
        },
        {
          key: "degProduction",
          displayName: "DEG生産量",
          type: "float",
        },
        {
          key: "triRawMaterial",
          displayName: "Tri原料",
          type: "float",
        },
        {
          key: "triRawMaterialDegConcentration",
          displayName: "Tri原料DEG濃度(0以上1以下)",
          type: "float",
        },
      ],
    },
    {
      key: "egPatternTri",
      displayName: "EG_T合成生産パターン",
      dependSheetFields: [
        {
          key: "productionPatternSheetId",
          displayName: "生産パターンシートId",
          type: "string",
        },
        {
          key: "facilityPatternName",
          displayName: "生産パターン名",
          type: "string",
        },
        {
          key: "tCompositionDegConsumption",
          displayName: "T合成DEG消費量",
          type: "float",
        },
        {
          key: "tCompositionEoConsumption",
          displayName: "T合成EO消費量",
          type: "float",
        },
        {
          key: "tegProduction",
          displayName: "TEG生産量",
          type: "float",
        },
        {
          key: "traEgProduction",
          displayName: "TraEG生産量",
          type: "float",
        },
      ],
    },
    {
      key: "productSheetNot4sMetaData",
      displayName: "4s以外製品シートメタデータ",
      dependSheetFields: [
        {
          key: "itemId",
          displayName: "項目ID",
          type: "string",
        },
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "itemKind",
          displayName: "項目種別",
          type: "string",
        },
        {
          key: "dataType",
          displayName: "データ型",
          type: "string",
        },
        {
          key: "enumParams",
          displayName: "enum選択肢",
          type: "string",
        },
        {
          key: "algorithmKind",
          displayName: "分類",
          type: "string",
        },
        {
          key: "targetItemCategory",
          displayName: "対象品目カテゴリ",
          type: "boolean",
        },
        {
          key: "targetItem",
          displayName: "対象品目",
          type: "boolean",
        },
        {
          key: "consumeItemCategory",
          displayName: "対象品目を消費する品目カテゴリ",
          type: "string",
        },
        {
          key: "consumeItem",
          displayName: "対象品目を消費する品目",
          type: "string",
        },
        {
          key: "reactor",
          displayName: "装置",
          type: "string",
        },
        {
          key: "tankId",
          displayName: "関連タンクID",
          type: "string",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
        {
          key: "outputKind",
          displayName: "出力分類",
          type: "string",
        },
      ],
    },
    {
      key: "firstDepartment",
      displayName: "1s",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "secondDepartmentEid",
      displayName: "2sEID",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "secondDepartmentEast",
      displayName: "2s東地区",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "thirdDepartment",
      displayName: "3s",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "financialTeam",
      displayName: "経理チーム",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "common",
      displayName: "共通",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "productionManagementCenter",
      displayName: "生産管理C",
      dependSheetFields: [
        {
          key: "itemName",
          displayName: "項目名",
          type: "string",
        },
        {
          key: "value",
          displayName: "値",
          type: "string",
        },
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "isFixed",
          displayName: "固定",
          type: "boolean",
        },
        {
          key: "department",
          displayName: "部署",
          type: "string",
        },
      ],
    },
    {
      key: "productMaster",
      displayName: "product_master",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "groupName",
          displayName: "グループ名",
          type: "string",
        },
      ],
    },
    {
      key: "reactorProductMaster",
      displayName: "reactor_product_master",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "reactorId",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "reactorProductionTime",
          displayName: "バッチあたり生産時間(反応器占有)",
          type: "integer",
        },
        {
          key: "shippingProductionTime",
          displayName: "バッチあたり生産時間(出荷可能)",
          type: "integer",
        },
        {
          key: "productionAmount",
          displayName: "バッチあたり生産量",
          type: "float",
        },
        {
          key: "tankId",
          displayName: "貯蔵タンクID",
          type: "string",
        },
        {
          key: "direct",
          displayName: "直充填かどうか",
          type: "string",
        },
      ],
    },
    {
      key: "materialMaster",
      displayName: "material_master",
      dependSheetFields: [
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
      ],
    },
    {
      key: "productMaterialMaster",
      displayName: "product_material_master",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "reactorId",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "requiredAmount",
          displayName: "使用量",
          type: "float",
        },
      ],
    },
    {
      key: "tankMaster",
      displayName: "tank_master",
      dependSheetFields: [
        {
          key: "tankId",
          displayName: "貯蔵タンクID",
          type: "string",
        },
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "capacityUpperLimit",
          displayName: "貯蔵量上限",
          type: "integer",
        },
        {
          key: "capacityLowerLimit",
          displayName: "貯蔵量下限",
          type: "integer",
        },
        {
          key: "targetStockAmount",
          displayName: "目標在庫下限",
          type: "integer",
        },
      ],
    },
    {
      key: "switchMaster",
      displayName: "switch_master",
      dependSheetFields: [
        {
          key: "reactorId",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "prevProductId",
          displayName: "切替前製品ID",
          type: "string",
        },
        {
          key: "nextProductId",
          displayName: "切替後製品ID",
          type: "string",
        },
        {
          key: "switchingTime",
          displayName: "洗浄時間",
          type: "integer",
        },
      ],
    },
    {
      key: "unavailableTank",
      displayName: "unavailable_tank",
      dependSheetFields: [
        {
          key: "tankId",
          displayName: "タンクID",
          type: "string",
        },
        {
          key: "startDatetime",
          displayName: "開始日時",
          type: "string",
        },
        {
          key: "endDatetime",
          displayName: "終了日時",
          type: "string",
        },
        {
          key: "forceTransport",
          displayName: "使用不可時に中身を別タンクに移送する",
          type: "string",
        },
      ],
    },
    {
      key: "production",
      displayName: "production",
      dependSheetFields: [
        {
          key: "reactorId",
          displayName: "反応器ID",
          type: "string",
        },
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "tankId",
          displayName: "タンクID",
          type: "string",
        },
        {
          key: "startDatetime",
          displayName: "生産開始日時",
          type: "date-time",
        },
        {
          key: "endDatetime",
          displayName: "生産終了日時",
          type: "date-time",
        },
        {
          key: "productionType",
          displayName: "スケジュールのタイプ",
          type: "string",
        },
        {
          key: "isFixed",
          displayName: "固定スケジュールかどうか",
          type: "boolean",
        },
      ],
    },
    {
      key: "shippingSchedule",
      displayName: "shipping_schedule",
      dependSheetFields: [
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "shippingAmount",
          displayName: "出荷量",
          type: "float",
        },
        {
          key: "date",
          displayName: "出荷日",
          type: "date",
        },
        {
          key: "tankId",
          displayName: "タンクID",
          type: "string",
        },
      ],
    },
    {
      key: "transport",
      displayName: "transport",
      dependSheetFields: [
        {
          key: "datetime",
          displayName: "日時",
          type: "date-time",
        },
        {
          key: "tankIdFrom",
          displayName: "移送元タンクID",
          type: "string",
        },
        {
          key: "tankIdTo",
          displayName: "移送先タンクID",
          type: "string",
        },
        {
          key: "amount",
          displayName: "移送量",
          type: "float",
        },
        {
          key: "isFixed",
          displayName: "固定スケジュールかどうか",
          type: "boolean",
        },
      ],
    },
    {
      key: "tankAmount",
      displayName: "tank_amount",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "tankId",
          displayName: "タンクID",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "productTankAmount",
      displayName: "product_tank_amount",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "rawMaterialConsumption",
      displayName: "raw_material_consumption",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "rawMaterialAmount",
      displayName: "raw_material_amount",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "rawMaterialPurchase",
      displayName: "raw_material_purchase",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "rawMaterialId",
          displayName: "原材料ID",
          type: "string",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "productGroupMaster",
      displayName: "product_group_master",
      dependSheetFields: [
        {
          key: "groupName",
          displayName: "グループ名",
          type: "string",
        },
      ],
    },
    {
      key: "materialConsumptionByProduct",
      displayName: "material_consumption_by_product",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "materialConsumptionByGroup",
      displayName: "material_consumption_by_group",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "rawMaterialName",
          displayName: "原材料名",
          type: "string",
        },
        {
          key: "groupName",
          displayName: "グループ名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "productionAmount",
      displayName: "production_amount",
      dependSheetFields: [
        {
          key: "date",
          displayName: "日付",
          type: "date",
        },
        {
          key: "productId",
          displayName: "製品ID",
          type: "string",
        },
        {
          key: "productName",
          displayName: "製品名",
          type: "string",
        },
        {
          key: "amount",
          displayName: "量",
          type: "float",
        },
      ],
    },
    {
      key: "score",
      displayName: "score",
      dependSheetFields: [
        {
          key: "name",
          displayName: "名前",
          type: "string",
        },
        {
          key: "value",
          displayName: "洗浄回数",
          type: "integer",
        },
      ],
    },
  ],
  configSchemas: [],
  querySettings: [
    {
      sheetKey: "firstDepartment",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "secondDepartmentEid",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "secondDepartmentEast",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "thirdDepartment",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "financialTeam",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "common",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "productionManagementCenter",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "materialConsumptionByGroup",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "materialConsumptionByProduct",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "productionAmount",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "rawMaterialConsumption",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "shippingSchedule",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "productTankAmount",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "tankAmount",
      sheetFieldSchemaName: "date",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "transport",
      sheetFieldSchemaName: "datetime",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "production",
      sheetFieldSchemaName: "startDatetime",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "unavailableReactor",
      sheetFieldSchemaName: "startDatetime",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
    {
      sheetKey: "unavailableTank",
      sheetFieldSchemaName: "startDatetime",
      queryFn: () => {
        return [
          {
            opStr: "==",
            value: "",
          },
        ];
      },
    },
  ],
};
