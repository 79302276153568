import loadable from "@loadable/component";

import { batchGanttDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeBatchGantt: StrictViewType = {
  key: "planiumChemicalBatchGanttView",
  displayName: "ガントチャート",
  dependSheets: batchGanttDependSheets,
  configSchemas: [],
  render: ViewRender as any,
};
