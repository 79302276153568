import loadable from "@loadable/component";

import { viewTypeDeliveryPlanDependSheets } from "./dependSheets";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDeliveryPlanView: StrictViewType = {
  key: "planiumChemicalDeliveryPlanView",
  displayName: "オーダー一覧",
  render: ViewRender as any,
  dependSheets: viewTypeDeliveryPlanDependSheets,
  configSchemas: [],
};
