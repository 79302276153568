import { DefaultButton, DefaultPalette, PrimaryButton } from "@fluentui/react";
import React, { useState, useCallback, useRef, useEffect } from "react";

import { Sheet, useGetSheet, useSetSheet } from "../../../../domain/Sheet";
import {
  useGetSheetDataRows,
  useSetSheetDataRow,
} from "../../../../domain/SheetDataRow";
import {
  SheetFieldSchema,
  useGetSheetFieldSchemas,
} from "../../../../domain/SheetFieldSchema";
import { csvToSheetAppend } from "../../../../helpers/csvToSheetAppend";
import { getSinglyLinkedSheetDataRowList } from "../../../../helpers/getSinglyLinkedSheetDataRowList";
import { themePrimaryRed } from "../../../../themes";
import {
  downloadAsCSV,
  downloadSomeCSVAsZip,
} from "../../../../utils/downloadAsCsv";
import { parseUploadedCSV } from "../../../../utils/parseUploadedCSV";
import { useAppNotificationManager } from "../../../AppProvider/AppNotificationProvider";
import { DialogPhaseType, useDialog } from "../../helpers/hooks";
import { ReferredFromState } from "../helper";

import { DialogSheetDelete } from "./DialogSheetDelete";
import { DialogSheetDeleteSome } from "./DialogSheetDeleteSome";
import { DialogSheetDuplicate } from "./DialogSheetDuplicate";
import { DialogSheetDuplicateSome } from "./DialogSheetDuplicateSome";

import { useAppRouteParams } from "@/AppRoutes";
import { getFileVersionDataExcel } from "@/api/files";
import { ContextMenuItem } from "@/components/GridTable/helpers";
import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import { useGetReferredFroms } from "@/domain/ReferredFrom";

const getCsvHeader = (sheetFieldSchemas: SheetFieldSchema[]) => {
  const csvHeader: { [fieldId in string]: string } = {};
  [...sheetFieldSchemas]
    .sort((a, b) => {
      return Math.sign(a.sort - b.sort);
    })
    .forEach(({ id, displayName }) => {
      csvHeader[id] = displayName;
    });
  return csvHeader;
};

/**
 * @description 複数の場所で利用されるcontextItemsとダイアログを生成
 */
export const useDialogContextMenuItemsSheet = () => {
  const { showSuccessNotification, showErrorNotification } =
    useAppNotificationManager();
  const { organizationId, versionId } = useAppRouteParams();
  const { versions } = useVersions();
  const version = versions.find((v) => v.id === versionId);
  const { hasSheetActionPermission, hasVersionActionPermission } =
    usePermissions();
  const hasVersionWritePermission = hasVersionActionPermission(
    version ?? null,
    "write"
  );

  const [selectedSheetIds, setSelectedSheetIds] = useState([] as string[]);

  const getReferredFroms = useGetReferredFroms();
  const [isIncludingReferencedSheets, setIsIncludingReferencedSheets] =
    useState<ReferredFromState>("waiting");

  // 選択シートが被参照シートかどうかチェック
  useEffect(() => {
    if (selectedSheetIds.length === 0) {
      setIsIncludingReferencedSheets("waiting");
      return;
    }
    (async () => {
      try {
        const referredFroms = await Promise.all(
          selectedSheetIds.map((sheetId) => getReferredFroms({ sheetId }))
        );
        if (referredFroms.flat().length > 0) {
          setIsIncludingReferencedSheets("including");
        } else {
          setIsIncludingReferencedSheets("notIncluding");
        }
      } catch (error) {
        showErrorNotification("シート参照のチェックに失敗しました", error);
        setIsIncludingReferencedSheets("notIncluding");
      }
    })();
    return () => {
      setIsIncludingReferencedSheets("waiting");
    };
  }, [selectedSheetIds]);

  const [deleteDialogPhaseType, setDeleteDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateDialogPhaseType, setDuplicateDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [deleteSomeDialogPhaseType, setDeleteSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const [duplicateSomeDialogPhaseType, setDuplicateSomeDialogPhaseType] =
    useState<DialogPhaseType>(null);

  const [csvUploadDialogPhaseType, setCSVUploadDialogPhaseType] =
    useState<DialogPhaseType>(null);
  const { renderDialogs, showErrorLocalDialog } = useDialog({
    dialogPhase: csvUploadDialogPhaseType,
    setDialogPhase: setCSVUploadDialogPhaseType,
  });

  // ダイアログを呼び出せる場合はread権限があると見なす
  const getSheet = useGetSheet();
  const getSheetDataRows = useGetSheetDataRows();
  const getSheetFieldSchemas = useGetSheetFieldSchemas();

  const setSheet = useSetSheet();
  const setSheetDataRow = useSetSheetDataRow();

  // download CSV
  const getCsvData = useCallback(
    async (sheetId: string, refVersionId?: string) => {
      const sheet = await getSheet(sheetId, {
        versionId: refVersionId ?? versionId,
      });
      const sheetDataRows = await getSheetDataRows({
        sheetId,
        versionId: refVersionId ?? versionId,
      });
      const sheetFieldSchemas = await getSheetFieldSchemas({
        sheetId,
        versionId: refVersionId ?? versionId,
      });
      if (!sheet || sheetFieldSchemas.length === 0) {
        showErrorNotification(
          "シートデータが不適切のため、ダウンロードできません"
        );
        return;
      }

      const { rows } = getSinglyLinkedSheetDataRowList(sheet, sheetDataRows);
      // スキーマの値が存在しない時は継ぎ足す
      const csvRows = rows.map(({ sheetDataCells }) => {
        sheetFieldSchemas.forEach(({ id }) => {
          if (!(id in sheetDataCells)) {
            sheetDataCells[id] = "";
          }
        });
        return sheetDataCells;
      });
      const csvHeader = getCsvHeader(sheetFieldSchemas);
      return {
        filename: `${sheet.displayName}.csv`,
        header: csvHeader,
        rows: csvRows,
      };
    },
    [versionId]
  );

  const runDownloadCSV = useCallback(
    async (sheetId: string, refVersionId?: string) => {
      try {
        const csvData = await getCsvData(sheetId, refVersionId);
        if (!csvData) return;
        const { filename, header, rows } = csvData;
        // CSVダウンロード実行
        await downloadAsCSV(filename, header, rows);
        showSuccessNotification("ダウンロードに成功しました");
      } catch (error) {
        showErrorNotification("ダウンロード中にエラーが発生しました", error);
      }
    },
    [getCsvData]
  );

  const runDownloadSomeCSVAsZip = useCallback(
    async (sheetIds: string[]) => {
      try {
        const csvDatas: Parameters<typeof downloadSomeCSVAsZip>[0] = [];
        for (const sheetId of sheetIds) {
          const csvData = await getCsvData(sheetId);
          if (csvData) {
            csvDatas.push(csvData);
          }
        }
        await downloadSomeCSVAsZip(csvDatas);
        showSuccessNotification("ダウンロードに成功しました");
      } catch (error) {
        showErrorNotification("ダウンロード中にエラーが発生しました", error);
      }
    },
    [getCsvData]
  );

  // upload csv
  const csvUploadInputRef = useRef<HTMLInputElement | null>(null);
  const forwardToFileInput = useCallback(
    (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined
    ) => {
      // inputを発火させる
      e && csvUploadInputRef.current?.dispatchEvent(new MouseEvent(e.type));
    },
    [csvUploadInputRef]
  );

  const uploadAsCSV = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      selectedSheetIds: string[]
    ) => {
      try {
        setCSVUploadDialogPhaseType("in_progress");
        const csvSheetDataRows = await parseUploadedCSV(event);
        const sheetId = selectedSheetIds[0];
        await csvToSheetAppend({
          sheetId,
          csvSheetDataRows,
          setSheet,
          setSheetDataRow,
          getSheet,
          getSheetDataRows,
          getSheetFieldSchemas,
        });
        setCSVUploadDialogPhaseType("success");
      } catch (error) {
        if (error instanceof Error) {
          showErrorLocalDialog([error.message]);
        } else {
          showErrorLocalDialog(["不明なエラー"]);
        }
      } finally {
        setSelectedSheetIds([]);
        csvUploadInputRef.current!.value = "";
      }
    },
    [versionId, csvUploadInputRef]
  );

  const getContextMenuItemsSheet = useCallback(
    (sheet: Sheet): ContextMenuItem[] => {
      const hasSheetWritePermission = hasSheetActionPermission(
        version ?? null,
        sheet.id,
        "write"
      );
      const hasSheetDeletePermission = hasSheetActionPermission(
        version ?? null,
        sheet.id,
        "delete"
      );
      return [
        {
          key: "i0",
          text: sheet.referTo
            ? "CSV形式で参照先のデータをダウンロード"
            : "CSV形式でデータをダウンロード",
          onClick: () => {
            (async () =>
              await runDownloadCSV(
                sheet.referTo?.sheetId ?? sheet.id,
                sheet.referTo?.versionId
              ))();
          },
          disabled: false,
        },
        {
          key: "i1",
          text: "CSV形式でデータを追加",
          onClick: (e) => {
            setSelectedSheetIds([sheet.id]);
            forwardToFileInput(e);
          },
          disabled: !hasSheetWritePermission,
          hidden: !!sheet.referTo,
        },
        {
          key: "i3",
          text: "シートを複製",
          onClick: () => {
            setSelectedSheetIds([sheet.id]);
            setDuplicateDialogPhaseType("confirm");
          },
          disabled: !hasVersionWritePermission,
          hidden: !!sheet.referTo,
        },
        {
          key: "i4",
          text: "シートを削除",
          onClick: () => {
            setSelectedSheetIds([sheet.id]);
            setDeleteDialogPhaseType("confirm");
          },
          disabled: !hasSheetDeletePermission,
          style: {
            color: hasSheetDeletePermission ? DefaultPalette.red : "",
          },
        },
      ];
    },
    [forwardToFileInput, csvUploadInputRef, version, hasSheetActionPermission]
  );

  const isDialogReady = isIncludingReferencedSheets !== "waiting";

  const renderDialogsSheet = useCallback(
    (sheets: Sheet[]) => {
      return (
        <>
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogSheetDelete
              sheets={sheets}
              selectedSheetIds={selectedSheetIds}
              setSelectedSheetIds={setSelectedSheetIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteDialogPhaseType}
              setDialogPhase={setDeleteDialogPhaseType}
            />
          )}
          <DialogSheetDuplicate
            sheets={sheets}
            selectedSheetIds={selectedSheetIds}
            setSelectedSheetIds={setSelectedSheetIds}
            dialogPhase={duplicateDialogPhaseType}
            setDialogPhase={setDuplicateDialogPhaseType}
          />
          {/* メッセージちらつきをおさえるための処理 */}
          {isDialogReady && (
            <DialogSheetDeleteSome
              sheets={sheets}
              selectedSheetIds={selectedSheetIds}
              setSelectedSheetIds={setSelectedSheetIds}
              isIncludingReferencedSheets={isIncludingReferencedSheets}
              dialogPhase={deleteSomeDialogPhaseType}
              setDialogPhase={setDeleteSomeDialogPhaseType}
            />
          )}
          <DialogSheetDuplicateSome
            sheets={sheets}
            selectedSheetIds={selectedSheetIds}
            setSelectedSheetIds={setSelectedSheetIds}
            dialogPhase={duplicateSomeDialogPhaseType}
            setDialogPhase={setDuplicateSomeDialogPhaseType}
          />
          {renderDialogs({
            inProgress: {
              title: `CSVデータをアップロードしています`,
            },
            success: {
              displayMessage: "CSVデータのアップロードが完了しました",
            },
            errorLocal: {
              displayMessage: "CSVデータのアップロードに失敗しました",
            },
          })}
          <input
            type="file"
            ref={csvUploadInputRef}
            accept={".csv"}
            onChange={async (e) => {
              await uploadAsCSV(e, selectedSheetIds);
            }}
            style={{ display: "none" }}
          />
        </>
      );
    },
    [
      selectedSheetIds,
      deleteDialogPhaseType,
      duplicateDialogPhaseType,
      deleteSomeDialogPhaseType,
      duplicateSomeDialogPhaseType,
      csvUploadDialogPhaseType,
      csvUploadInputRef,
      forwardToFileInput,
      isIncludingReferencedSheets,
    ]
  );

  const isDisabledDownload =
    selectedSheetIds.length === 0 ||
    !selectedSheetIds.every((id) =>
      hasSheetActionPermission(version ?? null, id, "read")
    );
  const isDisableDelete =
    selectedSheetIds.length === 0 ||
    !selectedSheetIds.every((id) =>
      hasSheetActionPermission(version ?? null, id, "delete")
    );
  const isDisableDuplicate =
    selectedSheetIds.length === 0 || !hasVersionWritePermission;

  const renderCSVDownloadButton = useCallback(
    () => (
      <DefaultButton
        text="CSVダウンロード"
        onClick={async () => {
          await runDownloadSomeCSVAsZip(selectedSheetIds);
        }}
        disabled={isDisabledDownload}
      />
    ),
    [selectedSheetIds, isDisabledDownload]
  );
  const renderDeleteSheetsButton = useCallback(
    () => (
      <PrimaryButton
        text="削除"
        onClick={() => {
          setDeleteSomeDialogPhaseType("confirm");
        }}
        theme={themePrimaryRed}
        disabled={isDisableDelete}
      />
    ),
    [selectedSheetIds, isDisableDelete]
  );
  const renderDuplicateSheetsButton = useCallback(
    () => (
      <PrimaryButton
        text="複製"
        onClick={() => {
          setDuplicateSomeDialogPhaseType("confirm");
        }}
        disabled={isDisableDuplicate}
      />
    ),
    [selectedSheetIds, isDisableDuplicate]
  );
  const renderExcelDownloadButton = useCallback(() => {
    return (
      <DefaultButton
        text="Excelで一括ダウンロード"
        onClick={async () => {
          try {
            await getFileVersionDataExcel({
              organizationId,
              versionId,
            });
            showSuccessNotification("データのダウンロードに成功しました");
          } catch (error) {
            showErrorNotification("データのダウンロードに失敗しました", error);
          }
        }}
      />
    );
  }, []);
  return {
    renderDialogsSheet,
    getContextMenuItemsSheet,
    renderDeleteSheetsButton,
    renderDuplicateSheetsButton,
    selectedSheetIds,
    setSelectedSheetIds,
    renderCSVDownloadButton,
    renderExcelDownloadButton,
  };
};
